export const BodyC1Icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11C0 10.4477 0.447715 10 1 10H23C23.5523 10 24 10.4477 24 11C24 11.5523 23.5523 12 23 12H1C0.447716 12 0 11.5523 0 11ZM1 47H14.2597V53H1V47ZM0 46H1H14.2597H15.2597V47V53V54H14.2597H1H0V53V47V46ZM3.5 49.5C3.22386 49.5 3 49.7239 3 50C3 50.2761 3.22386 50.5 3.5 50.5H11.7362C12.0123 50.5 12.2362 50.2761 12.2362 50C12.2362 49.7239 12.0123 49.5 11.7362 49.5H3.5ZM0.5 21C0.223858 21 0 21.2239 0 21.5C0 21.7761 0.223858 22 0.5 22H63.5C63.7761 22 64 21.7761 64 21.5C64 21.2239 63.7761 21 63.5 21H0.5ZM0 16.5C0 16.2239 0.223858 16 0.5 16H47.5C47.7761 16 48 16.2239 48 16.5C48 16.7761 47.7761 17 47.5 17H0.500001C0.223859 17 0 16.7761 0 16.5ZM0.5 31C0.223858 31 0 31.2239 0 31.5C0 31.7761 0.223858 32 0.5 32C0.776142 32 1 31.7761 1 31.5C1 31.2239 0.776142 31 0.5 31ZM3 31.5C3 31.2239 3.22386 31 3.5 31H50.5C50.7761 31 51 31.2239 51 31.5C51 31.7761 50.7761 32 50.5 32H3.5C3.22386 32 3 31.7761 3 31.5ZM0.5 36C0.223858 36 0 36.2239 0 36.5C0 36.7761 0.223858 37 0.5 37C0.776142 37 1 36.7761 1 36.5C1 36.2239 0.776142 36 0.5 36ZM0 41.5C0 41.2239 0.223858 41 0.5 41C0.776142 41 1 41.2239 1 41.5C1 41.7761 0.776142 42 0.5 42C0.223858 42 0 41.7761 0 41.5ZM3.5 36C3.22386 36 3 36.2239 3 36.5C3 36.7761 3.22386 37 3.5 37H50.5C50.7761 37 51 36.7761 51 36.5C51 36.2239 50.7761 36 50.5 36H3.5ZM3 41.5C3 41.2239 3.22386 41 3.5 41H50.5C50.7761 41 51 41.2239 51 41.5C51 41.7761 50.7761 42 50.5 42H3.5C3.22386 42 3 41.7761 3 41.5ZM0.5 26C0.223858 26 0 26.2239 0 26.5C0 26.7761 0.223858 27 0.5 27H63.5C63.7761 27 64 26.7761 64 26.5C64 26.2239 63.7761 26 63.5 26H0.5Z"
      fill="#18181B"
    />
  </svg>
);
