/**
 * @description This file contains methods to trigger adobe analytics events
 */

type CustomLinkType = 'internal' | 'external';

/**
 * @method trackFormWorkFlow
 * @description sets custom form workflow tracking
 * @param {string} formName (form name) [name of the form]
 * @param {string} action (action) [action of the form - initiated, step, completed, submitted, error]
 * @param {string} stepNumber (step number) [for pagination, set to 1 if only one page]
 * @param {string} pageLoad (page load) [string variable, set to true if action takes place during page load or false for click]
 * @param {string} errorMessage (Error Message) [optional parameter, do not set if no errors, or set to error, or could be a concatenated list of error messages]
 */
export const trackFormWorkFlow = (
  formName: string,
  action: string,
  stepNumber: string = '1',
  pageLoad: string = 'false',
  errorMessage: string = ''
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  window.s &&
    typeof window.s.pfFormWorkflow === 'function' &&
    window.s.pfFormWorkflow(
      formName,
      action,
      stepNumber,
      pageLoad,
      errorMessage
    );
};

/**
 * @method trackFormInput
 * @description sets custom form input tracking
 * @param {string} formName (form name) [name of the form]
 * @param {string} stepNumber (step number) [for pagination, set to 1 if only one page]
 * @param {string} formQuestion (form input question) [question statement of form field]
 * @param {string} formAnswer (form input answer) [answer statement of form field, set to empty string for any PII info]
 * @param {string} questionIndex (index of question) [question number in form]
 */
export const trackFormInput = (
  formName: string,
  stepNumber: string,
  formQuestion: string,
  formAnswer: string,
  questionIndex: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  window.s &&
    typeof window.s.pfFormWorkflowInput === 'function' &&
    window.s.pfFormWorkflowInput(
      formName,
      stepNumber,
      formQuestion,
      formAnswer,
      questionIndex
    );
};

/**
 * @method trackContentLiked
 * @description This method tracks the contents liked by the user
 * @param {String} contentTitle (Content Title) [title of content user liked]
 * @param {String} contentType (Content Type) [type of content, could be page, article, document, video, webinar, etc.]
 * @param {String} contentPlacement (Content Placement) [Content placement, or location on page, could be tile, rail, banner, etc. Optional]
 */
export const trackContentLiked = (
  contentTitle: string,
  contentType: string,
  contentPlacement: string = ''
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  window.s &&
    typeof window.s.pfContentLiked === 'function' &&
    window.s.pfContentLiked(contentTitle, contentType, contentPlacement);
};

/**
 * @method trackContentDisLiked
 * @description This method tracks the contents disliked by the user
 * @param {String} contentTitle (Content Title) [title of content user disliked]
 * @param {String} contentType (Content Type) [type of content, could be page, article, document, video, webinar, etc.]
 * @param {String} contentPlacement (Content Placement) [Content placement, or location on page, could be tile, rail, banner, etc. Optional]
 */
export const trackContentDisLiked = (
  contentTitle: string,
  contentType: string,
  contentPlacement: string = ''
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  window.s &&
    window.s.pfContentDisliked &&
    typeof window.s.pfContentDisliked === 'function' &&
    window.s.pfContentDisliked(contentTitle, contentType, contentPlacement);
};

/**
 * @method trackCustomLinkUsage
 * @description This method tracks a custom link(feature) usage
 * @param {string} featureName (feature name) [feature like that is being tracked]
 * @param {string} linkType (link type) [type of the link being tracked (internal or external)]
 */
export const trackCustomLinkUsage = (
  featureName: string,
  linkType: CustomLinkType
) => {
  const dataLayer = [];
  dataLayer.push({
    event: 'pfLinkName',
    pfLinkName: { linkName: featureName, linkType },
  });
  const event = new CustomEvent('pfAnalytics', { detail: dataLayer });
  document.querySelector('body')!.dispatchEvent(event);
};

/**
 * @method trackDownloadUsage
 * @description tracks the usage of a download feature
 * @param {string} fileName (file name) [name of the file downloaded]
 * @param {string} folderName (folder name) [optional, for nested folders and sub-folders use folder1/folder2/folder3]
 * @param {string} fileDownloadTitle (file download title) [optional]
 */
export const trackDownloadUsage = (
  fileName: string,
  folderName: string = '',
  fileDownloadTitle: string = ''
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  window.s &&
    typeof window.s.pfFileDownload === 'function' &&
    window.s.pfFileDownload(fileName, folderName, fileDownloadTitle);
};
