import { BodyC1Icon } from '../components/atoms/BodyC1Icon';
import { BodyC2Icon } from '../components/atoms/BodyC2Icon';
import { BodyC3Icon } from '../components/atoms/BodyC3Icon';
import { EmailAttributesIcon } from '../components/atoms/EmailAttributesIcon';
import { HeaderC1Icon } from '../components/atoms/HeaderC1Icon';
import { HeaderC2Icon } from '../components/atoms/HeaderC2Icon';
import { translate } from '../localization';
import { Option } from '../types/SelectInput';

export enum ContentType {
  BANNER = 'banner',
  EMAIL = 'email',
  DSP = 'dsp',
  SOCIAL = 'social media',
}

export enum DestinationFormat {
  BANNER = 'banner',
  EMAIL = 'email',
  DSP = 'dsp',
  SOCIAL = 'social',
  SOCIAL_MEDIA = 'social media',
}

export enum ContentTypeName {
  BANNER = 'Banner',
  EMAIL = 'Email',
  DSP = 'DSP',
  SOCIAL = 'Social',
}

export const PAGE_ROUTES = {
  DEFAULT: '/',
  HOME: '/home',
  GENERATE_CONTENT: '/generate-content',
  ACCESS_MANAGEMENT: '/user-management',
  SUMMARIZE_CONTENT: '/summarize-content',
  UPLOAD_GENERATE: '/upload-and-generate',
  QUALITY_VALIDATION: '/quality-validation',
  CALLBACK: '/callback',
  ANY: '*',
};

export const MENU_NAMES = {
  ACCESS: 'access',
  CREATE: 'create',
  UPLOAD: 'upload',
  HOME: 'home',
  HISTORY: 'history',
  CLOSE_SIDE_BAR: 'close-sidebar',
  QUALITY_VALIDATION: 'quality-validation',
};

export const ACTIVE_MENU_BY_ROUTE = {
  [PAGE_ROUTES.ACCESS_MANAGEMENT]: MENU_NAMES.ACCESS,
  [PAGE_ROUTES.GENERATE_CONTENT]: MENU_NAMES.CREATE,
  [PAGE_ROUTES.HOME]: MENU_NAMES.HOME,
  [PAGE_ROUTES.UPLOAD_GENERATE]: MENU_NAMES.UPLOAD,
  [PAGE_ROUTES.QUALITY_VALIDATION]: MENU_NAMES.QUALITY_VALIDATION,
};

export const toneStyleOptions: Option[] = [
  {
    id: '1',
    label: 'Precise',
    name: 'Precise',
  },
  {
    id: '2',
    label: 'Neutral',
    name: 'Balanced',
  },
  {
    id: '3',
    label: 'Creative',
    name: 'Creative',
  },
];

export const contentOptions: Option[] = [
  {
    label: translate('common_generate_content'),
    value: 'generate-content',
    id: '1',
  },
  {
    label: translate('common_summarize_content'),
    value: 'summarize-content',
    id: '2',
    disabled: true,
  },
];

export const REGENERATION_LIMIT = 4;
export const RETRY_REFERENCES_LIMIT = 3;
export const GENERATE_CONTENT = 'GENERATE_CONTENT';
export const SUMMARIZE = 'SUMMARIZE';

export const summarizationStatuses = {
  SUMMARIZATION_INITIATED: 'SUMMARIZATION_INITIATED',
  SUMMARIZATION_COMPLETED: 'SUMMARIZATION_COMPLETED',
  SUMMARIZATION_ERROR: 'SUMMARIZATION_ERROR',
};

export const fileUploadStatuses = {
  FILE_UPLOAD_INITIATED: 'FILE_UPLOAD_INITIATED',
  FILE_UPLOAD_COMPLETED: 'FILE_UPLOAD_COMPLETED',
  FILE_UPLOAD_ERROR: 'FILE_UPLOAD_ERROR',
};

export const CONTENT_EXTRACTION = 'CONTENT_EXTRACTION';

export const fileProcessStatuses = {
  FILE_PROCESS_INITIATED: 'FILE_PROCESS_INITIATED',
  FILE_PROCESS_COMPLETED: 'FILE_PROCESS_COMPLETED',
  FILE_PROCESS_ERROR: 'FILE_PROCESS_ERROR',
};

export const medicalContentTypesOptions = [
  {
    id: '1',
    value: '1',
    label: 'Study Publication Summary V1',
  },
];

export const SUPPORTED_MEDICAL_DOCUMENTS_EXTENSION = ['pdf'];

export const GENERATE_FILE_RETRIES = 2;

export const spacerPrefix = `@spacer`;

export const MEDICAL_GENERATE_CONTENT_RETRIES = 10;
export const MEDICAL_GENERATE_CONTENT_RETRIES_INTERVAL = 5;

export enum MedicalSummaryPollingStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
}

export const MEDICAL_FILE_UPLOAD_SIZE = 10; // 10 MB

const feedbackSliderCommonProps = {
  defaultValue: 0,
  markLabels: [],
  max: 5,
  min: 0,
  step: 1,
};

export const FEEDBACK_SLIDER_OPTIONS = [
  {
    label: translate('relevance'),
    name: 'relevance',
    ...feedbackSliderCommonProps,
  },
  {
    label: translate('usefulness'),
    name: 'usefulness',
    ...feedbackSliderCommonProps,
  },
  {
    label: translate('approvability'),
    name: 'approvability',
    ...feedbackSliderCommonProps,
    markLabels: ['NA', '1', '2', '3', '4', '5'],
  },
];

export const FEEDBACK_COMMENTS_LIMIT = 1000;

export const FEEDBACK_MODAL_TITLE_ID = 'feedback-form-title';
export const FEEDBACK_MODAL_DESCRIPTION_ID = 'feedback-form-description';

export const CHANNELS = {
  BANNER: 'banner',
  EMAIL: 'email',
  SOCIAL: 'social',
};

export const ANALYTICS = {
  GENERATE_FORM_NAME: 'generate-content',
  GENERATE_FORM_API_NAME: 'generate-content-api',
  REGENERATE_FORM_NAME: 'regenerate-content',
  REGENERATE_FORM_API_NAME: 'regenerate-content-api',
  PARTIAL_REGENERATE_FORM_NAME: 'partial-regenerate-content',
  PARTIAL_REGENERATE_API_FORM_NAME: 'partial-regenerate-content-api',
  REFINE_FORM_NAME: 'refine-content',
  REFINE_API_FORM_NAME: 'refine-content-api',
  PAGE_LEVEL_COPY_FORM_NAME: 'page-level-copy',
  ADDITIONAL_FEEDBACK_FORM_NAME: 'additional-feedback',
  OPTION_LEVEL_COPY_FORM_NAME: 'option-level-copy',
  SECTION_LEVEL_COPY_FORM_NAME: 'section-level-copy',
  STEP: '1',
  NUMBER_ONE: '1',
  PAGE_LOAD: 'false',
  FORM_ACTION: {
    // acceptable form actions for a form workflow tracking
    INITIATED: 'initiated',
    SUBMITTED: 'submitted',
    COMPLETED: 'completed',
    ERROR: 'error',
    STEP: 'step',
  },
  YES: 'yes',
  NO: 'no',
  LANGUAGE_COMPARE_NAME: 'translation | cta | compare',
  ADD_IMAGES_FORM_NAME: 'recommend-images',
  PAGE_LEVEL_TRANSLATION_FORM_NAME: 'page-level-translation',
  ADD_IMAGES_FORM_QUESTION: 'brand and channel',
  DOWNLOAD_IMAGES_FORM_NAME: 'download-image',
  DOWNLOAD_IMAGES_FORM_QUESTION: 'brand and channel',
  EXPORT_TO_DOC_SUCCESS_FORM_NAME: 'export-content-success',
  EXPORT_TO_DOC_ERROR_FORM_NAME: 'export-content-error',
  EXPORT_TO_DOC_FORM_QUESTION: 'brand and channel',
  CLAIMS_AND_REFERENCE_FORM_NAME: 'claims-and-references',
  CLAIMS_LIBRARY_LAUNCH_FORM_NAME: 'claims-library-launch',
  CLAIMS_AND_REFERENCE_LINK_NAME: 'references | link | claims-library-launch',
  ADMIN_PAGE_VISIT_NAME: 'navigation | link | admin-page',
  CONFIDENCE_SCORE_FORM_NAME: 'confidence-score',
  IMAGE_DEFICIENT_FORM_NAME: 'image-deficient',
  SECONDARY_CLAIMS_FORM_NAME: 'secondary-claims',
  DATA_DEFICIENCY_FORM_QUESTION: 'data-deficiency',
  SECONDARY_CLAIMS_FORM_QUESTION: 'secondary-claims',
  FULL_PREVIEW_FORM_NAME: 'full-preview',
  FILE_UPLOAD_FORM_NAME: 'file-upload',
  FILE_EXTRACTION_FORM_NAME: 'file-extraction',
  USER_ENCODED_NTID_QUESTION_NAME: 'userEncodedNtid',
};

export enum SortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export const ROLES = [
  { label: translate('general'), value: 'GENERAL', id: 'general' },
  { label: translate('admin'), value: 'ADMIN', id: 'admin' },
];

export const ROLE = {
  ADMIN: 'ADMIN',
};

export const CLAIMS_FETCH_RETRY_LIMIT = 3;

// images configs
export const BANNER_IMAGES_CONFIG = {
  noOfImagesExpected: 4,
  aspectRatio: {
    height: 150,
    width: 150,
  },
};

export const SOCIAL_MEDIA_IMAGES_CONFIG = {
  noOfImagesExpected: 3,
  aspectRatio: {
    height: 199,
    width: 199,
  },
};

export const EMAIL_IMAGES_CONFIG = {
  noOfImagesExpected: 3,
  aspectRatio: {
    height: 206,
    width: 206,
  },
};

export const EMAIL_MODULES_IMAGES_CONFIG = {
  noOfImagesExpected: 3,
  aspectRatio: {
    height: 199,
    width: 199,
  },
};

export enum ImageInfoConfig {
  ZERO_IMAGES = 'ZERO_IMAGES',
  ZERO_IMAGES_WITH_MAXED_OUT = 'ZERO_IMAGES_WITH_MAXED_OUT',
  INSUFFICIENT_IMAGES = 'INSUFFICIENT_IMAGES',
  INSUFFICIENT_IMAGES_WITH_MAXED_OUT = 'INSUFFICIENT_IMAGES_WITH_MAXED_OUT',
}

export enum ExportFormats {
  DOCX = 'docx',
}

export const recommendImageInfoConfigs = [
  {
    type: ImageInfoConfig.ZERO_IMAGES,
    labelText: translate('image_info_with_zero_images'),
    ctaText: translate('regenerate_text'),
  },
  {
    type: ImageInfoConfig.ZERO_IMAGES_WITH_MAXED_OUT,
    labelText: translate('image_info_with_zero_images_and_maxed_out'),
    ctaText: translate('request_more_content'),
  },
  {
    type: ImageInfoConfig.INSUFFICIENT_IMAGES,
    labelText: translate('image_info_with_insufficient_image'),
    ctaText: translate('regenerate_text_and_images'),
  },
  {
    type: ImageInfoConfig.INSUFFICIENT_IMAGES_WITH_MAXED_OUT,
    labelText: translate('image_info_with_zero_images_and_maxed_out'),
    ctaText: translate('request_more_content'),
  },
];

export const DROPDOWN_ITEM_HEIGHT = 56;
export const MARKETING_FORM_IGNORE_DD_BOTTOM_MARGIN = 150;
export const MARKETING_FORM_IGNORE_DD_TOP_MARGIN = 177;
export const USERS_IGNORE_DD_BOTTOM_MARGIN = 100;

export const FLOATING_MENU_OPTIONS = {
  REFERENCES: 'references',
  COMPARE: 'compare',
  TRANSLATE: 'translate',
  REFINE: 'refine',
};

export const commonlyUsedLangCode = ['en', 'es-ES', 'eslA', 'fr', 'pt'];
export const commonlyUsedCountryCode = ['GLOBAL', 'UNITED_STATES'];

export const MAX_EMAIL_MODULES_SELECTION_LIMIT = 5;

export const MAX_SELECTED_PRIMARY_MESSAGE_LIMIT = 3;

export const ALL_MODULES_FILTER = 'all-modules';
export const EMAIL_ATTRIBUTES_NAME = 'Email_Attributes';
export const CONVERT = 'CONVERT';
export const HCP = 'HCP';

export const emailAttributeNames: Record<string, string> = {
  HEADER: translate('header'),
  BODY: translate('body'),
  CLOSING_BODY: translate('body'),
  CALL_TO_ACTION: translate('common_call_to_action'),
  SUBHEADER: translate('subheader'),
  BULLETED_COPY: '',
  PRE_HEADER: translate('preheader'),
  SUBJECT_LINE: translate('subject_line'),
  PREVIEW_TEXT: translate('email_preview_text'),
};

export const emailModuleIcons: Record<string, JSX.Element> = {
  GL_Header_C1: HeaderC1Icon,
  GL_Header_C2: HeaderC2Icon,
  GL_Body_C1: BodyC1Icon,
  GL_Body_C2: BodyC2Icon,
  GL_Body_C3: BodyC3Icon,
  Email_Attributes: EmailAttributesIcon,
};
