import { atom as stateSegment } from 'jotai';
import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import { Option } from '../../../types/SelectInput';

type AvailableLanguages = {
  isLoading: boolean;
  data: Option[];
};

export const currentAvailableLanguagesStateSegment =
  stateSegment<AvailableLanguages>({ isLoading: false, data: [] });

currentAvailableLanguagesStateSegment.debugLabel =
  'currentAvailableLanguagesStateSegment';

// current language selected for translations
export const currentSelectedLanguageStateSegment = stateSegmentWithReset('');
currentSelectedLanguageStateSegment.debugLabel =
  'currentSelectedLanguageStateSegment';
