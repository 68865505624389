import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import { ClaimReferences } from '../../../types/Content';

type ContentClaims = {
  errorLoadingClaims: boolean;
  errorMessage: string;
  claims: ClaimReferences[];
  retries: number;
};
type Claims = { isLoading: boolean; map: Record<string, ContentClaims> };

export const contentClaimsStateSegment = stateSegmentWithReset<Claims>({
  isLoading: false,
  map: {},
});
contentClaimsStateSegment.debugLabel = 'contentClaimsStateSegment';
