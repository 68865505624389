import store from '../../index';
import { moduleContentsStateSegment } from '../../stateSegments/emailModulesStateSegment';
import { contentImagesStateSegment } from '../../stateSegments/contentImagesStateSegment';

// Function to update the selected image caption in the email module
export const updateSelectedImageCaptionInEmailModule = (
  imageId: string,
  emailModuleKey: string,
  langCode: string
) => {
  const emailModuleContents = store.get(moduleContentsStateSegment);
  const imageSegment = store.get(contentImagesStateSegment);

  // Retrieve the module and image data
  const module = emailModuleContents.get(emailModuleKey);

  if (module) {
    // Use Object.keys() to iterate over own properties of imageSegment
    Object.keys(imageSegment).forEach((optionId) => {
      const optionData = imageSegment[optionId];
      Object.values(optionData).forEach((data) => {
        const image = data.images.find((img) => img.id === imageId);
        if (image) {
          // Determine the caption based on the language code
          const selectedImageCaption =
            langCode === 'en'
              ? image.caption || ''
              : image.translatedCaption || '';

          // Update the module with the selected image caption
          store.set(moduleContentsStateSegment, (prev) => {
            const selectedModule = prev.get(emailModuleKey) || module;
            return new Map(prev).set(emailModuleKey, {
              ...selectedModule,
              selectedImageCaption,
            });
          });
        }
      });
    });
  }
};
