import { ANALYTICS } from '../../constants/common';
import { trackFormInput, trackFormWorkFlow } from './analyticEvents';

/**
 * @method trackFileUploadInitiate
 * @description this method tracks the file upload initiation
 * @param {string} formName (form name) [name of the form]
 */
export const trackFileUploadInitiate = (formName: string) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.INITIATED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );
};

/**
 * @method trackFileUploadComplete
 * @description this method tracks the file upload complete
 * @param {string} formName (form name) [name of the form]
 */
export const trackFileUploadComplete = (formName: string) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.COMPLETED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );
};

/**
 * @method trackFileUploadError
 * @description this method tracks the file upload error
 * @param {string} formName (form name) [name of the form]
 */
export const trackFileUploadError = (formName: string) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.ERROR,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );
};

/**
 * @method trackfileUploadUsage
 * @description tracks the usage of file upload feature
 * @param {string} formName (form name) [name of the form]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 */
export const trackfileUploadUsage = (
  fromName: string,
  userEncodedNtid: string
) => {
  // track user encode ntid
  if (userEncodedNtid !== '') {
    trackFormInput(
      fromName,
      ANALYTICS.NUMBER_ONE,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '1'
    );
  }
};
