/**
 * @description This file has methods to trigger analytic events for content feedback - like or dislike
 */

import get from 'lodash/get';
import { translate } from '../../localization';
import {
  trackContentDisLiked,
  trackContentLiked,
  trackFormInput,
  trackFormWorkFlow,
} from './analyticEvents';
import { ContentType } from '../../types/Content';
import { ANALYTICS } from '../../constants/common';

export const trackFeedback = (
  isLiked: boolean,
  contentItems: unknown[],
  index: number
) => {
  // preparing data to form the title for analytics feedback - starts
  const analyticsDataReference = {
    indication: 'indication',
    targetAudience: 'audience',
    segment: 'persona',
    sections: 'sections[0]',
    tone: 'tone',
    topics: 'topics',
    toneStyle: 'null',
  };

  const currentContent = contentItems[index];
  const isToAddAdvancedFilterDetails = index > 0;
  if (isToAddAdvancedFilterDetails) {
    analyticsDataReference.toneStyle = 'toneStyle';
  }
  const contentType: string = get(
    currentContent,
    'paramsUsed.contentType.label',
    ''
  );
  const platform = get(currentContent, 'paramsUsed.platform.label', '');
  const analyticsData = [];
  analyticsData.push(
    translate('adobe_feedback_title', {
      content_type: contentType === ContentType.SOCIAL ? platform : contentType,
    })
  );

  Object.entries(analyticsDataReference).forEach(([key, value]) => {
    const formData = get(currentContent, `paramsUsed.${value}.label`, '');
    if (formData && formData !== '---') {
      analyticsData.push(
        `${formData} ${translate(`marketing_form_fields_${key}`)}`
      );
    }
  });
  if (isToAddAdvancedFilterDetails) {
    const exclusions = get(currentContent, 'paramsUsed.exclusions', '');
    if (exclusions) {
      analyticsData.push(
        `${exclusions} ${translate(`marketing_form_fields_exclusions`)}`
      );
    }
  }
  const title = analyticsData.join(', ');
  // preparing data to form the title for analytics feedback - ends

  if (isLiked) {
    trackContentLiked(title, contentType);
  } else {
    trackContentDisLiked(title, contentType);
  }
};

/**
 * @method trackFeedbackFormInitiate
 * @description this method tracks the feedback form intiation
 * @param {string} formName (form name) [name of the form]
 */
export const trackFeedbackFormInitiate = (formName: string) => {
  // Form initiation tracking
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.INITIATED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );
};

/**
 * Tracks the submission of a feedback form, including details about the brand, channel, and user.
 *
 * @param {string} formName - The name of the form being submitted.
 * @param {string} brand - The brand associated with the form submission.
 * @param {string} channel - The  selectef channel for which the form is submitted.
 * @param {string} userEncodedNtid - The encoded NTID of the user submitting the form.
 */
export const trackFeedbackFormSubmit = (
  formName: string,
  brand: string,
  channel: string,
  userEncodedNtid: string
): void => {
  // Track the form submission workflow
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.SUBMITTED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    undefined
  );

  // Track the brand input associated with the form submission
  trackFormInput(`${formName}-submit`, ANALYTICS.STEP, 'brand', brand, '1');

  // Track the channel input associated with the form submission
  trackFormInput(`${formName}-submit`, ANALYTICS.STEP, 'channel', channel, '2');

  // Conditionally track the user encoded NTID if it is not undefined
  if (userEncodedNtid !== '') {
    trackFormInput(
      `${formName}-submit`,
      ANALYTICS.STEP,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '3'
    );
  }
};

/**
 * Tracks the completion of a feedback form submission, including details about the brand, channel, and user encoded NTID.
 *
 * @param {string} formName - The name of the form being completed.
 * @param {string} brand - The brand associated with the form submission.
 * @param {string} channel - The channel through which the form is submitted.
 * @param {string} userEncodedNtid - The encoded NTID of the user.
 */
export const trackFeedbackFormComplete = (
  formName: string,
  brand: string,
  channel: string,
  userEncodedNtid: string
): void => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.COMPLETED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );

  trackFormInput(`${formName}-complete`, ANALYTICS.STEP, 'brand', brand, '1');

  trackFormInput(
    `${formName}-complete`,
    ANALYTICS.STEP,
    'channel',
    channel,
    '2'
  );

  if (userEncodedNtid !== '') {
    trackFormInput(
      `${formName}-complete`,
      ANALYTICS.STEP,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '3'
    );
  }
};

/**
 * @method trackFeedbackFormError
 * @description this method tracks the system errors from the feedback  APIs
 * @param {string} formName (form name) [name of the form]
 */
export const trackFeedbackFormError = (formName: string) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.ERROR,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    'There has been an error in submitting feedback. The APIs are not able to submit feedback now.'
  );
};
