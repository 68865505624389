import { atom as stateSegment } from 'jotai';

type StartNew = {
  allowStartNew: boolean;
};

export const startNewStateSegment = stateSegment<StartNew>({
  allowStartNew: false,
});

startNewStateSegment.debugLabel = 'startNewStateSegment';
