import { BASE_URL, BASE_URL_UAT, BASE_URL_PROD } from '../constants/apiRoutes';

import {
  DEPLOYED_ENV_NAME_DEV,
  DEPLOYED_ENV_NAME_PROD,
  DEPLOYED_ENV_NAME_UAT,
  //   DEPLOYED_ENV_NAME_PROD,
} from '../constants/deployedEnvNames';

import { getEnvironmentName } from './getEnvironmentName';

export const getAPIGatewayEndpoint = () => {
  const deployedEnvironmentName = getEnvironmentName();

  if (deployedEnvironmentName === DEPLOYED_ENV_NAME_DEV) {
    return BASE_URL;
  }

  if (deployedEnvironmentName === DEPLOYED_ENV_NAME_UAT) {
    return BASE_URL_UAT;
  }

  if (deployedEnvironmentName === DEPLOYED_ENV_NAME_PROD) {
    return BASE_URL_PROD;
  }

  return BASE_URL;
};
