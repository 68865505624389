import { RESET } from 'jotai/utils';
import store from '../../index';
import { currentSelectedLanguageStateSegment } from '../../stateSegments/availableLanguagesStateSegment';
import { contentClaimsStateSegment } from '../../stateSegments/contentClaimsStateSegment';
import { contentImagesStateSegment } from '../../stateSegments/contentImagesStateSegment';
import {
  contentListStateSegment,
  selectedChannel,
  selectedDestinationFormat,
} from '../../stateSegments/contentListSegment';
import { contentPaginationStateSegment } from '../../stateSegments/contentPaginationStateSegment';
import {
  activeEmailScreenStateSegment,
  emailContentParentId,
  moduleContentsStateSegment,
  selectedModulesStateSegment,
} from '../../stateSegments/emailModulesStateSegment';
import { contentFeedbackStateSegment } from '../../stateSegments/feedbackSegment';
import imagesDataStateSegment from '../../stateSegments/imagesDataStateSegment';
import { currentLanguageTabStateSegment } from '../../stateSegments/languageTabsStateSegment';
import { contentParamsSegment } from '../../stateSegments/paramsSegment';
import { preGenerateCliamsStateSegment } from '../../stateSegments/preGenerateClaimsStateSegment';
import { refineOptionsStateSegment } from '../../stateSegments/refineOptionsStateSegment';
import { contentTranslationStateSegment } from '../../stateSegments/translationSegment';
import { supportingInformationStateSegment } from '../../stateSegments/supportingInformationStateSegment';
import { imageCaptionStateSegment } from '../../stateSegments/imageCaptionStateSegment';

export const clearAllValues = () => {
  store.set(contentListStateSegment, RESET);
  store.set(contentPaginationStateSegment, RESET);
  store.set(contentTranslationStateSegment, RESET);
  store.set(currentLanguageTabStateSegment, RESET);
  store.set(currentSelectedLanguageStateSegment, RESET);
  store.set(contentFeedbackStateSegment, RESET);
  store.set(contentClaimsStateSegment, RESET);
  store.set(refineOptionsStateSegment, RESET);
  store.set(contentImagesStateSegment, RESET);
  store.set(imagesDataStateSegment, RESET);
  store.set(imageCaptionStateSegment, RESET);
  store.set(preGenerateCliamsStateSegment, RESET);
  store.set(contentParamsSegment, RESET);
  store.set(selectedModulesStateSegment, RESET);
  store.set(moduleContentsStateSegment, RESET);
  store.set(activeEmailScreenStateSegment, RESET);
  store.set(selectedChannel, RESET);
  store.set(selectedDestinationFormat, RESET);
  store.set(emailContentParentId, RESET);
  store.set(supportingInformationStateSegment, RESET);
};
