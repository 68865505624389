export const BodyC2Icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 14C20 13.4477 20.4477 13 21 13H43C43.5523 13 44 13.4477 44 14C44 14.5523 43.5523 15 43 15H21C20.4477 15 20 14.5523 20 14ZM27 45H38.2597C38.812 45 39.2597 45.4477 39.2597 46V50C39.2597 50.5523 38.812 51 38.2597 51H27C26.4477 51 26 50.5523 26 50V46C26 45.4477 26.4477 45 27 45ZM25 46C25 44.8954 25.8954 44 27 44H38.2597C39.3643 44 40.2597 44.8954 40.2597 46V50C40.2597 51.1046 39.3643 52 38.2597 52H27C25.8954 52 25 51.1046 25 50V46ZM28.5 47.5C28.2239 47.5 28 47.7239 28 48C28 48.2761 28.2239 48.5 28.5 48.5H36.7362C37.0123 48.5 37.2362 48.2761 37.2362 48C37.2362 47.7239 37.0123 47.5 36.7362 47.5H28.5ZM8.5 19C8.22386 19 8 19.2239 8 19.5C8 19.7761 8.22386 20 8.5 20H55.5C55.7761 20 56 19.7761 56 19.5C56 19.2239 55.7761 19 55.5 19H8.5ZM0 29.5C0 29.2239 0.223858 29 0.5 29C0.776142 29 1 29.2239 1 29.5C1 29.7761 0.776142 30 0.5 30C0.223858 30 0 29.7761 0 29.5ZM3.5 29C3.22386 29 3 29.2239 3 29.5C3 29.7761 3.22386 30 3.5 30H63.5C63.7761 30 64 29.7761 64 29.5C64 29.2239 63.7761 29 63.5 29H3.5ZM0 24.5C0 24.2239 0.223858 24 0.5 24C0.776142 24 1 24.2239 1 24.5C1 24.7761 0.776142 25 0.5 25C0.223858 25 0 24.7761 0 24.5ZM3.5 24C3.22386 24 3 24.2239 3 24.5C3 24.7761 3.22386 25 3.5 25H63.5C63.7761 25 64 24.7761 64 24.5C64 24.2239 63.7761 24 63.5 24H3.5ZM0 34.5C0 34.2239 0.223858 34 0.5 34C0.776142 34 1 34.2239 1 34.5C1 34.7761 0.776142 35 0.5 35C0.223858 35 0 34.7761 0 34.5ZM0.5 39C0.223858 39 0 39.2239 0 39.5C0 39.7761 0.223858 40 0.5 40C0.776142 40 1 39.7761 1 39.5C1 39.2239 0.776142 39 0.5 39ZM3 34.5C3 34.2239 3.22386 34 3.5 34H63.5C63.7761 34 64 34.2239 64 34.5C64 34.7761 63.7761 35 63.5 35H3.5C3.22386 35 3 34.7761 3 34.5ZM3.5 39C3.22386 39 3 39.2239 3 39.5C3 39.7761 3.22386 40 3.5 40H63.5C63.7761 40 64 39.7761 64 39.5C64 39.2239 63.7761 39 63.5 39H3.5Z"
      fill="#18181B"
    />
  </svg>
);
