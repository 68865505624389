import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

export type UserDetail = {
  userNtid: string;
  userNtidEncoded: string;
};

export const userDetailSegment = stateSegmentWithReset<UserDetail>({
  userNtid: '',
  userNtidEncoded: '',
});

userDetailSegment.debugLabel = 'userDetailSegment';
