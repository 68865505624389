import { fetchReferences } from '../../../../services/Content';
import { ReferencesResponseData } from '../../../../types/Content';
import store from '../../index';
import { contentClaimsStateSegment } from '../../stateSegments/contentClaimsStateSegment';

export const getClaimsReferences = async (
  contentId: string,
  noOfRetries: number,
  sessionId: string,
  action: string
) => {
  store.set(contentClaimsStateSegment, (prev) => {
    return {
      ...prev,
      isLoading: true,
      map: {
        ...prev.map,
        [contentId]: {
          claims: [],
          retries: noOfRetries,
          errorLoadingClaims: false,
          errorMessage: '',
        },
      },
    };
  });

  const { data, success } = await fetchReferences(contentId, sessionId, action);
  if (success && data) {
    setTimeout(() => {
      store.set(contentClaimsStateSegment, (prev) => {
        return {
          ...prev,
          isLoading: false,
          map: {
            ...prev.map,
            [contentId]: {
              claims: (data as ReferencesResponseData).contentReferences, // updated key from claimReferences as per swagger
              retries: noOfRetries,
              errorLoadingClaims: false,
              errorMessage: '',
            },
          },
        };
      });
    }, 3000);
  } else {
    store.set(contentClaimsStateSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        map: {
          ...prev.map,
          [contentId]: {
            errorLoadingClaims: true,
            errorMessage: 'There was an error loading claims.',
            claims: [],
            retries: noOfRetries,
          },
        },
      };
    });
  }
};
