import { fetchAvailableLanguage } from '../../../../services/Content';
import { Option } from '../../../../types/SelectInput';
import store from '../../index';
import { currentAvailableLanguagesStateSegment } from '../../stateSegments/availableLanguagesStateSegment';

export const loadAvailableLanguages = async () => {
  store.set(currentAvailableLanguagesStateSegment, (prev) => {
    return { ...prev, isLoading: true };
  });

  const { success, data } = await fetchAvailableLanguage();

  if (success) {
    const options = (data as Option[]).map((el) => ({
      id: el.id,
      value: el.id,
      label: el.label,
    }));

    store.set(currentAvailableLanguagesStateSegment, (prev) => {
      return { ...prev, isLoading: false, data: options };
    });
  } else {
    store.set(currentAvailableLanguagesStateSegment, (prev) => {
      return { ...prev, isLoading: false, data: [] };
    });
  }
};
