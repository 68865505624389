import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { exchangeCodeForToken } from '../../../services/SSO'; // Adjust the import path as needed

const SSOCallback: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (code) {
      exchangeCodeForToken(code)
        .then(() => {
          // After exchanging the code for tokens, navigate to the Dashboard or any desired route
          // if state was not found than redirect to home page for now
          if (state) {
            const retrievedUrl = decodeURIComponent(state);
            const storedState = sessionStorage.getItem('oauth_state')
              ? decodeURIComponent(sessionStorage.getItem('oauth_state')!)
              : '';
            if (state === storedState) {
              window.location.href = retrievedUrl;
              sessionStorage.removeItem('oauth_state');
            } else {
              // eslint-disable-next-line no-console
              console.error(
                'State validation failed. This might indicate a CSRF attack.'
              );
            }
          } else {
            navigate('/');
          }
        })
        .catch((err) => {
          // Handle the error. For simplicity, we redirect back to root but you might want to handle differently
          // eslint-disable-next-line no-console
          console.error('Error during token exchange:', err);
          navigate('/');
        });
    } else {
      // No code present in the URL. This is unusual, handle as required. Redirecting to root as an example.
      navigate('/');
    }
  }, [navigate]);

  return <p>Processing...</p>;
};

SSOCallback.displayName = 'SSOCallback';
export default SSOCallback;
