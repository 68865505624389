import { FC } from 'react';
import ContentLoadingAnimation from '../../atoms/ContentLoadingAnimation';
import styles from './index.style.module.css';
import { translate } from '../../../localization';

interface SectionLoadingIndicatorProps {
  text?: string;
}

const SectionLoadingIndicator: FC<SectionLoadingIndicatorProps> = ({
  text,
}) => {
  return (
    <div
      className="flex flex-vertical-center escape-copy"
      aria-live="assertive"
    >
      <div className={styles['section-loading-indicator__icon']}>
        <ContentLoadingAnimation />
      </div>
      <div
        className={`${styles['section-loading-indicator__text']} escape-copy`}
      >
        {text || translate('common_loading')}
      </div>
    </div>
  );
};

SectionLoadingIndicator.displayName = 'SectionLoadingIndicator';
export default SectionLoadingIndicator;
