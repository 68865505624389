import { ImageCaptionData } from '../../../../services/Content';
import { ContentTypeImage } from '../../../../types/Content';
import store from '../../index';
import { contentImagesStateSegment } from '../../stateSegments/contentImagesStateSegment';

export const updateImageCaption = (
  contentId: string,
  optionId: string,
  data: ImageCaptionData
) => {
  let updatedImages: ContentTypeImage[] = [];

  store.set(contentImagesStateSegment, (prev) => {
    const existingImages = prev[contentId]?.[optionId]?.images || [];

    updatedImages = existingImages.map((image: ContentTypeImage) => {
      const captionObj = data.images.find((c) => c.imageId === image.id);
      return {
        ...image,
        ...(captionObj ? { caption: captionObj.caption } : {}),
      };
    });

    return {
      ...prev,
      [contentId]: {
        ...prev[contentId],
        [optionId]: {
          ...prev[contentId][optionId],
          isLoading: false,
          imagesReferenceFrame: data.imagesReferenceFrame || '',
          errorFetchingImages: false,
          images: updatedImages,
        },
      },
    };
  });
};
