import React, { FC, useRef } from 'react';
import { useEventListener } from 'usehooks-ts';

import styles from './PointingTooltip.module.css';

export enum TooltipPosition {
  TOP_CENTER = 'TOP_CENTER',
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
const tooltipPositonClassMap = {
  [TooltipPosition.TOP_CENTER]: styles['tooltip__text--top-center'],
  [TooltipPosition.TOP_LEFT]: styles['tooltip__text--top-left'],
  [TooltipPosition.TOP_RIGHT]: styles['tooltip__text--top-right'],
  [TooltipPosition.BOTTOM_CENTER]: styles['tooltip__text--bottom-center'],
  [TooltipPosition.BOTTOM_LEFT]: styles['tooltip__text--bottom-left'],
  [TooltipPosition.BOTTOM_RIGHT]: styles['tooltip__text--bottom-right'],
  [TooltipPosition.LEFT]: styles['tooltip__text--left'],
  [TooltipPosition.RIGHT]: styles['tooltip__text--right'],
};

type PointingTooltipProps = {
  text: string;
  children: React.ReactNode;
  showTooltip: boolean;
  toolTipPosition?: TooltipPosition;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  id: string;
  classes?: string;
};

const PointingTooltip: FC<PointingTooltipProps> = ({
  text,
  children,
  showTooltip,
  toolTipPosition = TooltipPosition.TOP_LEFT,
  onMouseEnter,
  onMouseLeave,
  id,
  classes = '',
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const mappedVariantClass = tooltipPositonClassMap[toolTipPosition];

  const visibilityClass = !showTooltip ? styles['tooltip__text--hidden'] : '';

  const mappedToolTipClass = [
    styles.tooltip__text,
    visibilityClass,
    mappedVariantClass,
    classes,
  ].join(' ');

  useEventListener('mouseenter', onMouseEnter, ref);
  useEventListener('mouseleave', onMouseLeave, ref);

  return (
    <div className={styles.tooltip} ref={ref} aria-describedby={id}>
      {children}
      <div
        className={mappedToolTipClass}
        role="tooltip"
        aria-hidden={!showTooltip}
        id={id}
      >
        {text}
      </div>
    </div>
  );
};

PointingTooltip.displayName = 'PointingTooltip';

export default PointingTooltip;
