import { AxiosProgressEvent } from 'axios';
import {
  FILE_UPLOAD,
  FILE_PROCESS,
  FILE_PROCESS_STATUS,
  FILE_DELETE,
  GET_USER_SESSION_URL,
} from '../constants/apiRoutes';
import { ApiError } from '../types/Content';
import {
  FileUploadIntiateResult,
  FileUploadIntiateResponse,
  FileProcessIntiateResult,
  FileProcessIntiateResponse,
  FileProcessStatusCheckResponse,
  FileProcessStatusCheckResult,
  FileDeleteResult,
  FileDeleteResponse,
  GetSessionReponse,
  GetSessionResult,
  UploadFileResponse,
} from '../types/FileUpload';
import { httpClient, HTTPClientRequestPayload } from '../utils/httpClient';
import Logger from '../utils/logger';

/**
 * Initiates an API call to upload a file.
 *
 * This function sends a POST request to the server to initiate the file upload process.
 * It handles the response and errors appropriately, logging any errors encountered during the process.
 *
 * @param {string} fileName - The name of the file to be uploaded.
 * @returns {Promise<FileUploadIntiateResult>} - A promise that resolves to the result of the file upload initiation.
 */
export const intiateFileUploadApiCall = async (
  fileName: string
): Promise<FileUploadIntiateResult> => {
  const payload: HTTPClientRequestPayload = {
    fileName,
  };
  try {
    const response = await httpClient.post<FileUploadIntiateResponse>(
      FILE_UPLOAD,
      payload
    );
    const { data, success } = response as FileUploadIntiateResponse;
    if (success && data) {
      return { success, data };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

/**
 * Initiates an API call to process a file after upload.
 *
 * This function sends a POST request to the server to initiate the file processing process.
 * It handles the response and errors appropriately, logging any errors encountered during the process.
 *
 * @param {string} sessionId - The session ID associated with the file upload.
 * @param {string} fileName - The name of the file that has been uploaded.
 * @param {string} fileUploadStatus - The status of the file upload, used to determine if processing can proceed.
 * @returns {Promise<FileProcessIntiateResult>} - A promise that resolves to the result of the file processing initiation.
 */

export const intiateFileProcessApiCall = async (
  sessionId: string,
  fileName: string,
  fileUploadStatus: string
): Promise<FileProcessIntiateResult> => {
  const payload: HTTPClientRequestPayload = {
    sessionId,
    fileName,
    fileUploadStatus,
  };
  try {
    const response = await httpClient.post<FileProcessIntiateResponse>(
      FILE_PROCESS,
      payload
    );
    const { data, success } = response as FileProcessIntiateResponse;
    if (success && data) {
      return { success, data };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const fileProcessStatusCheckApiCall = async (
  sessionId: string
): Promise<FileProcessStatusCheckResult> => {
  try {
    const response = await httpClient.get<FileProcessStatusCheckResponse>(
      FILE_PROCESS_STATUS(sessionId)
    );
    const { data, success } = response as FileProcessStatusCheckResponse;
    if (success && data) {
      return { success, data };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const deleteFileRequestApiCall = async (
  sessionId: string,
  fileName: string
): Promise<FileDeleteResult> => {
  try {
    const response = await httpClient.delete<FileDeleteResponse>(
      FILE_DELETE(sessionId, fileName)
    );
    const { data, success } = response as FileDeleteResponse;
    if (success && data) {
      return { success, data };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const getSessionDetailsApiCall = async (
  action: string
): Promise<GetSessionResult> => {
  try {
    const USER_SESSION_URL = GET_USER_SESSION_URL(action);
    const response = await httpClient.get<GetSessionReponse>(USER_SESSION_URL);
    const { data, success } = response as GetSessionReponse;
    if (success && data) {
      return { success, data };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const uploadFileApiCall = async (
  preSignedURL: string,
  file: File,
  signal: AbortSignal,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
): Promise<UploadFileResponse> => {
  try {
    const response = await httpClient.put<UploadFileResponse>(
      preSignedURL,
      file,
      { 'Content-Type': file.type },
      {
        signal,
        onUploadProgress,
      },
      true
    );
    const { status, success } = response as UploadFileResponse;
    if (success && status === 200) {
      return { success, status };
    }
    return {
      success: false,
      status,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
      status: 500,
    };
  }
};
