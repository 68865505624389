/**
 * @description This file has methods to trigger analytic events for generate/regenerate content feature
 */

import { ANALYTICS } from '../../constants/common';
import { trackFormInput, trackFormWorkFlow } from './analyticEvents';

/**
 * @method trackFormInitiate
 * @description this method tracks the form initiation
 * @param {string} formName (form name) [name of the form]
 */
export const trackFormInitiate = (formName: string) => {
  // Form initiation tracking
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.INITIATED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );
};

/**
 * @method trackFormSubmit
 * @description this method tracks the form submission
 * @param {string} formName (form name) [name of the form]
 */
export const trackFormSubmit = (formName: string) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.SUBMITTED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );
};

/**
 * @method trackFormComplete
 * @description this method tracks the completion of  form submission
 * @param {string} formName (form name) [name of the form]
 */
export const trackFormComplete = (formName: string) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.COMPLETED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );
};

/**
 * @method trackFormError
 * @description this method tracks the system errors of the form
 * @param {string} formName (form name) [name of the form]
 * @param {string} message (message ) [error message to be send]
 */
export const trackFormError = (formName: string, message: string) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.ERROR,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    message
  );
};

/**
 * @method trackFormQuestion
 * @description this method tracks the form Question and answer
 * @param {string} formName (form name) [name of the form]
 * @param {string} question (question ) [question of the form]
 * @param {string} answer (answer ) [answer of the question]
 * @param {string} questionIndex (question index ) [index of the question]
 */
export const trackFormQuestion = (
  formName: string,
  question: string,
  answer: string,
  questionIndex: string
) => {
  trackFormInput(formName, ANALYTICS.STEP, question, answer, questionIndex);
};
