/**
 * @description This file has methods to trigger analytic events for copy feature
 */

import { ANALYTICS } from '../../constants/common';
import { trackFormInput } from './analyticEvents';

/**
 * @method trackImageDeficient
 * @description this method track type of content-copy with selected brand and channel
 * @param {string} formName (form name) [name of the form]
 * @param {string} brand (selected brand name) [name of the brand selected in the prompt form]
 * @param {string} channel (selected channel name) [name of the channel selected in the prompt form]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 */
export const trackImageDeficient = (
  formName: string,
  brand: string,
  channel: string,
  targetAudience: string,
  userEncodedNtid: string
) => {
  trackFormInput(formName, ANALYTICS.STEP, 'brand', `${brand}`, '1');
  trackFormInput(formName, ANALYTICS.STEP, 'channel', `${channel}`, '2');
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'targetAudience',
    `${targetAudience}`,
    '3'
  );

  if (userEncodedNtid !== '') {
    trackFormInput(
      formName,
      ANALYTICS.STEP,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '4'
    );
  }
};
