import { FC } from 'react';
import { createPortal } from 'react-dom';
import {
  RiAlertLine,
  RiCheckboxCircleLine,
  RiCloseLine,
  RiInformationLine,
} from '@remixicon/react';
import styles from './SnackBar.module.css';
import IconButton from '../../molecules/IconButton';
import Button from '../Button';

export enum SnackBarVariant {
  NEUTRAL = 'NEUTRAL',
  INFO = 'INFO',
  SUCCESS = 'success',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum SnackBarPosition {
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  BOTTOM_CENTER = 'bottom-center',
}

const stylesConfiguration = {
  [SnackBarVariant.NEUTRAL]: {
    wrapperClassName: styles['snackbar__container-neutral'],
  },
  [SnackBarVariant.INFO]: {
    wrapperClassName: styles['snackbar__container-info'],
  },
  [SnackBarVariant.SUCCESS]: {
    wrapperClassName: styles['snackbar__container-success'],
  },
  [SnackBarVariant.ERROR]: {
    wrapperClassName: styles['snackbar__container-error'],
  },
  [SnackBarVariant.WARNING]: {
    wrapperClassName: styles['snackbar__container-warning'],
  },
};

const positionConfiguration = {
  [SnackBarPosition.TOP_CENTER]: styles['snackbar__container--top-center'],
  [SnackBarPosition.TOP_RIGHT]: styles['snackbar__container--top-right'],
  [SnackBarPosition.BOTTOM_CENTER]:
    styles['snackbar__container--bottom-center'],
};

interface SnackBarProps {
  theme?: SnackBarVariant;
  notificationText: string;
  hideSnackBar?: () => void;
  position?: SnackBarPosition;
  showCloseIcon?: boolean;
  showCTAButton?: boolean;
  ctaCallback?: () => void;
  ctaLabel?: string;
  classes?: string;
  titleText?: string;
}

const iconByTheme = {
  [SnackBarVariant.NEUTRAL]: null,
  [SnackBarVariant.INFO]: <RiInformationLine size={24} />,
  [SnackBarVariant.SUCCESS]: <RiCheckboxCircleLine size={24} />,
  [SnackBarVariant.ERROR]: <RiAlertLine size={24} />,
  [SnackBarVariant.WARNING]: <RiAlertLine size={24} />,
};

const SnackBar: FC<SnackBarProps> = ({
  theme = SnackBarVariant.NEUTRAL,
  titleText,
  notificationText,
  hideSnackBar = () => {},
  position = SnackBarPosition.BOTTOM_CENTER,
  showCloseIcon = true,
  showCTAButton = false,
  ctaCallback = () => {},
  ctaLabel = '',
  classes,
}) => {
  const snackBarThemeClass = stylesConfiguration[theme];
  const mappedClass = [
    styles.snackbar__container,
    snackBarThemeClass.wrapperClassName,
    positionConfiguration[position],
  ].join(' ');
  const icon = iconByTheme[theme];

  return createPortal(
    <div
      className={`${mappedClass} ${classes}`}
      role="alert"
      aria-live="assertive"
      aria-label={`Notification: ${notificationText}`}
      tabIndex={0}
    >
      {icon && <div className={styles['snackbar--icon']}>{icon}</div>}
      <div
        className={`${styles.snackbar__container__msgwrapper} ${
          titleText ? styles['snackbar__notification-titled'] : ''
        }`}
      >
        <p className={styles.snackbar__container__notification__title}>
          {titleText}
        </p>
        <p className={styles.snackbar__container__notification__text}>
          {notificationText}
        </p>
      </div>
      {showCTAButton && (
        <Button
          type="button"
          variant="outline"
          onClick={ctaCallback}
          colorScheme="primary"
          className={styles['snackbar--cta']}
        >
          {ctaLabel}
        </Button>
      )}
      {showCloseIcon && (
        <IconButton onClick={hideSnackBar} ariaLabel="Close Snackbar">
          <RiCloseLine size={24} />
        </IconButton>
      )}
    </div>,
    document.getElementById('snackbar-portal') as HTMLDivElement
  );
};

SnackBar.displayName = 'SnackBar';
export default SnackBar;
