import { getPrimaryLanguage } from '../services/SSO';
import Logger from '../utils/logger';

import en from './locales/en.json';
import eslatam from './locales/es-latam.json';
import eseu from './locales/es-eu.json';
import de from './locales/de.json';

type LangJSON = Record<
  string,
  string | Record<string, string | Record<string, string>>
>;
// const translations: LangJSON = en;
const langMap: Record<string, LangJSON> = {
  en,
  eslatam,
  eseu,
  de,
  'es-419': eslatam,
  'es-ES': eseu,
};

// this will be replaced with actual translate
export const translate = (
  key: string,
  params?: Record<string, string>,
  lang: string = getPrimaryLanguage()
): string => {
  const keys: string[] = key.split('.');
  let translation: Record<string, string> | undefined = langMap[lang] as
    | Record<string, string>
    | undefined;

  let i: number = 0;
  while (i < keys.length) {
    const currentKey: string = keys[i];
    translation =
      translation &&
      (translation[currentKey] as unknown as
        | Record<string, string>
        | undefined);

    if (!translation) {
      Logger.error(new Error(`Translation not found for key: ${key}`));
      return key;
    }

    // just to fix typescript error
    const newLocal: number = i + 1;
    i = newLocal;
  }

  if (!params) return translation as unknown as string;

  return (translation as unknown as string).replace(
    /\{\{(\w+)\}\}/g,
    (match, paramName) => {
      return params[paramName] || match;
    }
  );
};
