import get from 'lodash/get';
import store from '../../index';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { getEncodedUserNtid, getVersionIds } from '../../helpers/common';
import { convertTextToLowerCase } from '../../../../utils/convertTextToLowerCase';
import { contentImagesStateSegment } from '../../stateSegments/contentImagesStateSegment';
import { fetchRecommendedImages as fetchRecommendedImagesFunction } from '../../../../services/Content';
import {
  ANALYTICS,
  BANNER_IMAGES_CONFIG,
  CHANNELS,
  EMAIL_IMAGES_CONFIG,
  SOCIAL_MEDIA_IMAGES_CONFIG,
} from '../../../../constants/common';
import { contentPaginationStateSegment } from '../../stateSegments/contentPaginationStateSegment';
import {
  trackImageDeficient,
  trackRecommendImageDeficient,
} from '../../../../services/Analytics';

export const fetchRecommendedImages = async (
  optionId: string,
  currentVersion: number,
  sessionId: string,
  action: string,
  callback: (success: boolean) => void
) => {
  const existingPrimaryParams = get(
    store.get(contentListStateSegment).contentList,
    `[${currentVersion}].paramsUsed`,
    {}
  );

  const versionIds = getVersionIds();
  const contentId = versionIds[currentVersion];

  const requestPayload = {
    brand: convertTextToLowerCase(
      get(existingPrimaryParams, 'brand.label', '')
    ),
    channel: convertTextToLowerCase(
      get(existingPrimaryParams, 'contentType.name', '')
    ),
    contentId,
    sessionId,
    action,
  };

  const regenerationDetails = {
    optionId,
  };

  store.set(contentImagesStateSegment, (prev) => {
    return {
      ...prev,
      [contentId]: {
        ...prev[contentId],
        [optionId]: {
          isLoading: true,
          imagesReferenceFrame: '',
          errorFetchingImages: false,
          images: [],
        },
      },
    };
  });

  const { success, data } = await fetchRecommendedImagesFunction(
    requestPayload,
    regenerationDetails
  );

  if (success && data) {
    store.set(contentImagesStateSegment, (prev) => {
      return {
        ...prev,
        [contentId]: {
          ...prev[contentId],
          [optionId]: {
            isLoading: false,
            imagesReferenceFrame: data.imagesReferenceFrame || '',
            errorFetchingImages: false,
            images: data.images.map((image) => ({
              name: image.imageName,
              altText: image.imageName,
              frameId: optionId,
              id: image.imageId,
            })),
          },
        },
      };
    });

    const imagesLength = data.images.length;
    const { BANNER, EMAIL, SOCIAL } = CHANNELS;
    const channel = requestPayload.channel?.toLowerCase();
    const isBannerImageDeficient =
      channel === BANNER &&
      imagesLength < BANNER_IMAGES_CONFIG.noOfImagesExpected;
    const isEmailImageDeficient =
      channel === EMAIL &&
      imagesLength < EMAIL_IMAGES_CONFIG.noOfImagesExpected;
    const isSocialMediaImageDeficient =
      channel === SOCIAL &&
      imagesLength < SOCIAL_MEDIA_IMAGES_CONFIG.noOfImagesExpected;
    if (
      isBannerImageDeficient ||
      isEmailImageDeficient ||
      isSocialMediaImageDeficient
    ) {
      const contentListState = store.get(contentListStateSegment);
      const currentViewVersion = store.get(contentPaginationStateSegment);
      const { contentList: contentItems } = contentListState;
      trackRecommendImageDeficient(ANALYTICS.YES);
      const targetAudience = get(
        contentItems[currentViewVersion],
        'paramsUsed.audience.label',
        ''
      )?.toLowerCase();
      trackImageDeficient(
        ANALYTICS.IMAGE_DEFICIENT_FORM_NAME,
        requestPayload.brand,
        requestPayload.channel,
        targetAudience,
        getEncodedUserNtid()
      );
    } else {
      trackRecommendImageDeficient(ANALYTICS.NO);
    }
    callback(true);
  } else {
    store.set(contentImagesStateSegment, (prev) => {
      return {
        ...prev,
        [contentId]: {
          ...prev[contentId],
          [optionId]: {
            isLoading: false,
            errorFetchingImages: true,
            imagesReferenceFrame: '',
            images: [],
          },
        },
      };
    });
    callback(false);
  }
};
