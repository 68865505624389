import ContentLoadingAnimation from '../../atoms/ContentLoadingAnimation';
import styles from './PageLoader.module.css';

const PageLoader = () => {
  return (
    <div className={styles['page__loader-wrapper']}>
      <div className={styles.page__loader}>
        <ContentLoadingAnimation />
      </div>
    </div>
  );
};

PageLoader.displayName = 'PageLoader';
export default PageLoader;
