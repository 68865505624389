export const EmailAttributesIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 26C0 25.4477 0.447715 25 1 25H63C63.5523 25 64 25.4477 64 26C64 26.5523 63.5523 27 63 27H1C0.447715 27 0 26.5523 0 26ZM0 32C0 31.4477 0.447715 31 1 31H63C63.5523 31 64 31.4477 64 32C64 32.5523 63.5523 33 63 33H1C0.447715 33 0 32.5523 0 32ZM1 37C0.447715 37 0 37.4477 0 38C0 38.5523 0.447715 39 0.999999 39H53C53.5523 39 54 38.5523 54 38C54 37.4477 53.5523 37 53 37H1Z"
      fill="#18181B"
    />
  </svg>
);
