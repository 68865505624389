import { toneStyleOptions } from '../../constants/common';

export const ACTION_NAMES = {
  LOAD_BRANDS_START: 'LOAD_BRANDS_START',
  LOAD_BRANDS_COMPLETE: 'LOAD_BRANDS_COMPLETE',
  LOAD_BRANDS_ERROR: 'LOAD_BRANDS_ERROR',
  UPDATE_BRAND: 'UPDATE_BRAND',
  LOAD_INDICATIONS_START: 'LOAD_INDICATIONS_START',
  LOAD_INDICATIONS_COMPLETE: 'LOAD_INDICATIONS_COMPLETE',
  LOAD_INDICATIONS_ERROR: 'LOAD_INDICATIONS_ERROR',
  UPDATE_INDICATION: 'UPDATE_INDICATION',
  LOAD_COUNTRIES_START: 'LOAD_COUNTRIES_START',
  LOAD_COUNTRIES_COMPLETE: 'LOAD_COUNTRIES_COMPLETE',
  LOAD_COUNTRIES_ERROR: 'LOAD_COUNTRIES_ERROR',
  UPDATE_COUNTRY: 'UPDATE_COUNTRY',
  LOAD_AUDIENCE_START: 'LOAD_AUDIENCE_START',
  LOAD_AUDIENCE_COMPLETE: 'LOAD_AUDIENCE_COMPLETE',
  LOAD_AUDIENCE_ERROR: 'LOAD_AUDIENCE_ERROR',
  UPDATE_AUDIENCE: 'UPDATE_AUDIENCE',
  LOAD_CONTENT_TYPE_START: 'LOAD_CONTENT_TYPE_START',
  LOAD_CONTENT_TYPE_COMPLETE: 'LOAD_CONTENT_TYPE_COMPLETE',
  LOAD_CONTENT_TYPE_ERROR: 'LOAD_CONTENT_TYPE_ERROR',
  UPDATE_CONTENT_TYPE: 'UPDATE_CONTENT_TYPE',
  LOAD_SEGMENT_START: 'LOAD_SEGMENT_START',
  LOAD_SEGMENT_COMPLETE: 'LOAD_SEGMENT_COMPLETE',
  LOAD_SEGMENT_ERROR: 'LOAD_SEGMENT_ERROR',
  UPDATE_SEGMENT: 'UPDATE_PERSONA',
  LOAD_SECTION_START: 'LOAD_SECTION_START',
  LOAD_SECTION_COMPLETE: 'LOAD_SECTION_COMPLETE',
  LOAD_SECTION_ERROR: 'LOAD_SECTION_ERROR',
  UPDATE_SECTION: 'UPDATE_SECTION',
  LOAD_TONE_START: 'LOAD_TONE_START',
  LOAD_TONE_COMPLETE: 'LOAD_TONE_COMPLETE',
  LOAD_TONE_ERROR: 'LOAD_TONE_ERROR',
  UPDATE_TONE: 'UPDATE_TONE',
  LOAD_PLATFORM_START: 'LOAD_PLATFORM_START',
  LOAD_PLATFORM_COMPLETE: 'LOAD_PLATFORM_COMPLETE',
  LOAD_PLATFORM_ERROR: 'LOAD_PLATFORM_ERROR',
  UPDATE_PLATFORM: 'UPDATE_PLATFORM',
  LOAD_TOPICS_START: 'LOAD_TOPICS_START',
  LOAD_TOPICS_COMPLETE: 'LOAD_TOPICS_COMPLETE',
  LOAD_TOPICS_ERROR: 'LOAD_TOPICS_ERROR',
  UPDATE_TOPICS: 'UPDATE_TOPICS',
  LOAD_MODULES_START: 'LOAD_MODULES_START',
  LOAD_MODULES_COMPLETE: 'LOAD_MODULES_COMPLETE',
  LOAD_MODULES_ERROR: 'LOAD_MODULES_ERROR',
  UPDATE_TONE_STYLE: 'UPDATE_TONE_STYLE',
  UPDATE_EXCLUSION: 'UPDATE_EXCLUSION',
  CLEAR_ALL_SELECTED_VALUES: 'CLEAR_ALL_SELECTED_VALUES',
  RESET_SECONDARY_FORM_VALUES: 'RESET_SECONDARY_FORM_VALUES',
  UPDATE_FEEDBACK: 'UPDATE_FEEDBACK',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  TRANSLATE_START_CONTENT: 'TRANSLATE_START_CONTENT',
  TRANSLATE_COMPLETE_CONTENT: 'TRANSLATE_COMPLETE_CONTENT',
  PARTIAL_REGENERATE_START_CONTENT: 'PARTIAL_REGENERATE_START_CONTENT',
  PARTIAL_REGENERATE_COMPLETE_CONTENT: 'PARTIAL_REGENERATE_COMPLETE_CONTENT',
  IMAGE_RECOMMENDATION_START_CONTENT: 'IMAGE_RECOMMENDATION_START_CONTENT',
  IMAGE_RECOMMENDATION_COMPLETE_CONTENT:
    'IMAGE_RECOMMENDATION_COMPLETE_CONTENT',
  LOAD_PROMPT_FIELD_OPTIONS_ERROR: 'LOAD_PROMPT_FIELD_OPTIONS_ERROR',
  UPDATE_CONTENT_CONFIG: 'UPDATE_CONTENT_CONFIG',
  LOAD_WF_TASK_DETAILS_START: 'LOAD_WF_TASK_DETAILS_START',
  LOAD_WF_TASK_DETAILS_COMPLETE: 'LOAD_WF_TASK_DETAILS_COMPLETE',
  LOAD_WF_TASK_DETAILS_ERROR: 'LOAD_WF_TASK_DETAILS_ERROR',
  UPDATE_UNAVAILABLE_OPTIONS_FIELD: 'UPDATE_UNAVAILABLE_OPTIONS_FIELD',
  CLEAR_WF_DEFAULTS: 'CLEAR_WF_DEFAULTS',
};

// Canned Params state defaults
export const promptFeildsLoadingDefaults = {
  isLoadingBrands: false,
  isErrorLoadingBrands: false,
  isLoadingIndications: false,
  isErrorLoadingIndications: false,
  isLoadingCountries: false,
  isErrorLoadingCountries: false,
  isLoadingAudiences: false,
  isErrorLoadingAudiences: false,
  isLoadingTones: false,
  isErrorFetchingOptions: false,
  isLoadingContentTypes: false,
  isErrorLoadingContentTypes: false,
  isLoadingSections: false,
  isErrorLoadingSections: false,
  isLoadingSegments: false,
  isErrorLoadingSegments: false,
  isLoadingTopics: false,
  isErrorLoadingTopics: false,
  isLoadingPlatforms: false,
  isErrorLoadingModules: false,
  isLoadingModules: false,
  isErrorLoadingPlatforms: false,
  isErrorLoadingTones: false,
  isLoadingWFTaskDetails: false,
  isErrorLoadingWFTaskDetails: false,
  isPromptOptionsUnavailable: false,
};

// Current canned params defaults
export const currentPromptFeildsDefaults = {
  currentSelectedBrand: '',
  currentSelectedIndication: '',
  currentSelectedCountry: '',
  currentSelectedAudience: '',
  currentSelectedContentType: '',
  currentSelectedSegment: '',
  currentSelectedSections: [],
  currentSelectedTone: '',
  currentSelectedTopic: [],
  currentSelectedPlatform: '',
};

export const wfTaskDetails = {
  isWFTaskDetailsAvailable: false,
  isWFDetailsRetrieved: false,
  wfTaskID: '',
  wfTaskName: '',
  wProjectId: '',
  wfProjectName: '',
};

export const promptPreselectionDefaults = {
  brand: '',
  indication: '',
  audience: '',
  channel: '',
  platform: '',
  language: '',
};

// Enhancement defaults
export const marketingEnhancementDefaults = {
  currentAvailableToneStyleOptions: [...toneStyleOptions],
  currentSelectedToneStyle: 'Balanced',
  currentSelectedExclusion: '',
  currentSelectedInclusion: '',
};
