export const HeaderC2Icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <g clipPath="url(#clip0_1957_8248)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60 12H4C2.34315 12 1 13.3431 1 15V31C1 32.6569 2.34315 34 4 34H60C61.6569 34 63 32.6569 63 31V15C63 13.3431 61.6569 12 60 12ZM4 11C1.79086 11 0 12.7909 0 15V31C0 33.2091 1.79086 35 4 35H60C62.2091 35 64 33.2091 64 31V15C64 12.7909 62.2091 11 60 11H4ZM25 52H38.2597V58H25V52ZM24 51H25H38.2597H39.2597V52V58V59H38.2597H25H24V58V52V51ZM27.5 54.5C27.2239 54.5 27 54.7239 27 55C27 55.2761 27.2239 55.5 27.5 55.5H35.7362C36.0123 55.5 36.2362 55.2761 36.2362 55C36.2362 54.7239 36.0123 54.5 35.7362 54.5H27.5ZM28.404 27.51L28.414 27.5H36.504C36.6353 27.499 36.7609 27.4463 36.8537 27.3534C36.9465 27.2605 36.9991 27.1348 37 27.0035V18.9965C37 18.7225 36.778 18.5 36.504 18.5H27.496C27.3647 18.501 27.2391 18.5537 27.1463 18.6466C27.0534 18.7395 27.0009 18.8652 27 18.9965V27.0035C27 27.1351 27.0522 27.2613 27.1452 27.3544C27.2382 27.4475 27.3644 27.4999 27.496 27.5H28.3935L28.404 27.51ZM36 19.5V24.5L33 21.5L28 26.5V19.5H36ZM33 22.914L36 25.914V26.5H29.414L33 22.914ZM29.2929 22.2071C29.4804 22.3946 29.7348 22.5 30 22.5C30.2652 22.5 30.5196 22.3946 30.7071 22.2071C30.8946 22.0196 31 21.7652 31 21.5C31 21.2348 30.8946 20.9804 30.7071 20.7929C30.5196 20.6054 30.2652 20.5 30 20.5C29.7348 20.5 29.4804 20.6054 29.2929 20.7929C29.1054 20.9804 29 21.2348 29 21.5C29 21.7652 29.1054 22.0196 29.2929 22.2071ZM0 40C0 39.4477 0.447715 39 1 39H63C63.5523 39 64 39.4477 64 40C64 40.5523 63.5523 41 63 41H1C0.447715 41 0 40.5523 0 40ZM1 45C0.447715 45 0 45.4477 0 46C0 46.5523 0.447714 47 0.999999 47H47C47.5523 47 48 46.5523 48 46C48 45.4477 47.5523 45 47 45H1ZM0 75.5C0 75.2239 0.223858 75 0.5 75H47.5C47.7761 75 48 75.2239 48 75.5C48 75.7761 47.7761 76 47.5 76H0.500001C0.223859 76 0 75.7761 0 75.5ZM0.5 63C0.223858 63 0 63.2239 0 63.5C0 63.7761 0.223858 64 0.5 64H63.5C63.7761 64 64 63.7761 64 63.5C64 63.2239 63.7761 63 63.5 63H0.5Z"
        fill="#18181B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1957_8248">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
