import store from '../../index';
import {
  contentFeedbackStateSegment,
  FeedbackData,
} from '../../stateSegments/feedbackSegment';

/* feedback actions */
export const onFeedBackUpdate = (
  contentId: string,
  feedbackData: FeedbackData
) => {
  store.set(contentFeedbackStateSegment, (prev) => {
    return {
      ...prev,
      [contentId]: {
        ...prev[contentId],
        ...feedbackData,
      },
    };
  });
};
