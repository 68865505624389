const QualityCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.8837 15.973L7.64373 11.7351L9.05373 10.3258L11.8837 13.1544L19.6437 5.49825L21.0637 6.90755L11.8937 15.973H11.8837Z"
        fill="#000"
      />
      <path
        d="M19.89 10.9455C19.94 11.2954 19.97 11.6452 19.97 12.005C19.97 16.4028 16.4 19.971 12 19.971C7.6 19.971 4.03 16.4028 4.03 12.005C4.03 7.6072 7.6 4.03898 12 4.03898C13.71 4.03898 15.29 4.57871 16.59 5.49825L18.04 4.04898C16.36 2.76962 14.27 2 12 2C6.48 2.01 2 6.48776 2 12.005C2 17.5222 6.48 22 12 22C17.52 22 22 17.5222 22 12.005C22 11.0455 21.86 10.1159 21.6 9.23638L19.89 10.9455Z"
        fill="#000"
      />
    </svg>
  );
};

export default QualityCheck;
