import { Provider as JotaiProvider } from 'jotai';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/root';
// import globalStore from './store/globalStore';
import marketingApplicationStore from './store/markettingContentGeneration';
import BackgroundTaskManager from './components/widgets/BackGroundTaskManager';

function App() {
  return (
    <JotaiProvider store={marketingApplicationStore}>
      <RouterProvider router={router} />
      <BackgroundTaskManager />
    </JotaiProvider>
  );
}

App.displayName = 'App';
export default App;
