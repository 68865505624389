export const BodyC3Icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 19C20 18.4477 20.4477 18 21 18H43C43.5523 18 44 18.4477 44 19C44 19.5523 43.5523 20 43 20H21C20.4477 20 20 19.5523 20 19ZM27 40H38.2597C38.812 40 39.2597 40.4477 39.2597 41V45C39.2597 45.5523 38.812 46 38.2597 46H27C26.4477 46 26 45.5523 26 45V41C26 40.4477 26.4477 40 27 40ZM25 41C25 39.8954 25.8954 39 27 39H38.2597C39.3643 39 40.2597 39.8954 40.2597 41V45C40.2597 46.1046 39.3643 47 38.2597 47H27C25.8954 47 25 46.1046 25 45V41ZM28.5 42.5C28.2239 42.5 28 42.7239 28 43C28 43.2761 28.2239 43.5 28.5 43.5H36.7362C37.0123 43.5 37.2362 43.2761 37.2362 43C37.2362 42.7239 37.0123 42.5 36.7362 42.5H28.5ZM24.5 24C24.2239 24 24 24.2239 24 24.5C24 24.7761 24.2239 25 24.5 25H39.5C39.7761 25 40 24.7761 40 24.5C40 24.2239 39.7761 24 39.5 24H24.5ZM0 29.5C0 29.2239 0.223858 29 0.5 29H63.5C63.7761 29 64 29.2239 64 29.5C64 29.7761 63.7761 30 63.5 30H0.5C0.223858 30 0 29.7761 0 29.5ZM0.5 34C0.223858 34 0 34.2239 0 34.5C0 34.7761 0.223859 35 0.500001 35H47.5C47.7761 35 48 34.7761 48 34.5C48 34.2239 47.7761 34 47.5 34H0.5Z"
      fill="#18181B"
    />
  </svg>
);
