import get from 'lodash/get';
import { ContentType } from '../../../../constants/common';
import { fetchTranslatedContent } from '../../../../services/Content';
import { getPrimaryLanguage } from '../../../../services/SSO';
import { convertTextToLowerCase } from '../../../../utils/convertTextToLowerCase';
import {
  doesImageCaptionExists,
  doesImageRecommendExists,
  setSectionLoader,
} from '../../helpers/common';
import store from '../../index';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { contentTranslationStateSegment } from '../../stateSegments/translationSegment';
import { updateTranslatedCaptionOnImageSegment } from './updateTranslatedCaption';

export const partialTranslateContent = async (
  currentViewVersion: number,
  languageCode: string,
  optionId: string,
  sections: string[],
  loaderPath: string,
  action?: string,
  destinationFormat?: string
) => {
  const currentData = store.get(contentListStateSegment).contentList[
    currentViewVersion
  ];
  const contentType = convertTextToLowerCase(
    get(currentData, 'paramsUsed.contentType.name', '')
  );

  const contentId = get(currentData, `${getPrimaryLanguage()}.id`);

  store.set(contentTranslationStateSegment, (prev) => {
    return { ...prev, isPartialTranslating: true };
  });

  if (contentType === ContentType.BANNER) {
    setSectionLoader({
      langCode: getPrimaryLanguage(),
      optionId,
      showLoader: true,
    });
  }

  const { success, data } = await fetchTranslatedContent({
    contentId,
    languageId: languageCode,
    contentType,
    action,
    optionId,
    sections,
    destinationFormat,
  });

  store.set(contentTranslationStateSegment, (prev) => {
    return { ...prev, isPartialTranslating: false };
  });

  if (contentType === ContentType.BANNER) {
    setSectionLoader({
      langCode: getPrimaryLanguage(),
      optionId,
      showLoader: false,
    });
  }

  if (success && data) {
    store.set(contentTranslationStateSegment, (prev) => {
      return {
        ...prev,
        isTranslatingContent: false,
        map: {
          ...prev.map,
          [contentId]: {
            ...prev.map[contentId],
            langCode: languageCode,
            data,
          },
        },
      };
    });

    if (
      contentType === ContentType.EMAIL &&
      doesImageCaptionExists(contentId) &&
      doesImageRecommendExists(data)
    ) {
      updateTranslatedCaptionOnImageSegment(contentId, data);
    }
  } else {
    store.set(contentTranslationStateSegment, (prev) => {
      return {
        ...prev,
        errorTranslatingContent: true,
        isTranslatingContent: false,
        isPartialTranslating: false,
        errorPartialTranslating: true,
        map: {
          ...prev.map,
          [contentId]: {
            ...prev.map[contentId],
            retryParams: {
              currentViewVersion,
              langCode: languageCode,
              optionId,
              sections,
              loaderPath,
            },
          },
        },
      };
    });
  }
};
