import { updateFeedback } from '../../../../services/Content';
import { getVersionIds } from '../../helpers/common';
import { FeedbackData } from '../../stateSegments/feedbackSegment';
import { onFeedBackUpdate } from './onFeedBackUpdate';

export const postFeedback = (
  contentIndex: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feedbackData: any,
  previousFeedbackData: Pick<FeedbackData, 'isLiked' | 'isDisliked'>,
  sessionId: string,
  callback: (isSuccess: boolean) => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLiked, isDisliked, ...payloadData } = feedbackData;

  const versionIds = getVersionIds();

  const contentId = versionIds[contentIndex];
  updateFeedback({ id: contentId, sessionId, ...feedbackData }).then((data) => {
    const { success } = data;
    if (success) {
      callback(true);
    } else {
      callback(false);
      onFeedBackUpdate(contentId, { ...feedbackData, ...previousFeedbackData });
    }
  });
};
