import { ANALYTICS } from '../../constants/common';
import { trackCustomLinkUsage, trackFormInput } from './analyticEvents';

/**
 * @method trackPageLevelTranslation
 * @description this method tracks the page level translation
 * @param {string} formName (form name) [name of the form]
 * @param {string} selectedLanguage (selected language) [selected Language value]
 * @param {string} brand (brand name)  [brand used to create the content]
 * @param {string} channel (channel name) [channel used to create the content]
 * @param {string} targetAudience (target audience) [target audience used to create the content]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 * @param {object} [additionalData] (optional) [additional key-value pairs to track]
 */
export const trackPageLevelTranslation = (
  formName: string,
  selectedLanguage: string,
  brand: string,
  channel: string,
  targetAudience: string,
  userEncodedNtid: string,
  additionalData?: Record<string, string>
) => {
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'language',
    `${selectedLanguage}`,
    '1'
  );
  trackFormInput(formName, ANALYTICS.STEP, 'brand', `${brand}`, '2');
  trackFormInput(formName, ANALYTICS.STEP, 'channel', `${channel}`, '3');
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'targetAudience',
    `${targetAudience}`,
    '4'
  );

  if (userEncodedNtid !== '') {
    trackFormInput(
      formName,
      ANALYTICS.STEP,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '5'
    );
  }

  // Track additional data if provided
  if (additionalData) {
    Object.entries(additionalData).forEach(([key, value], index) => {
      trackFormInput(
        formName,
        ANALYTICS.STEP,
        key,
        value,
        (index + 6).toString() // Start index after 5
      );
    });
  }
};

/**
 * @method trackLanguageCompare
 * @description This method tracks the compare mode click for comparing two different language
 */
export const trackLanguageCompare = (languageCompareName: string) => {
  trackCustomLinkUsage(languageCompareName, 'internal');
};
