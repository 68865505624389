import get from 'lodash/get';
import { TranslatedData } from '../store/markettingContentGeneration/stateSegments/translationSegment';
import { GeneratedModuleContent, TranslatedContent } from '../types/Content';
import { Option } from '../types/SelectInput';

// Used for Current Email Module Export
/**
 * Adds language label & language code to the current module translated content.
 *
 * This function processes a record of translated content, mapping each entry to include
 * language label & language code, if the boolean flag, indicating whether to include
 * translated language information, is `true`.
 *
 * @param {Record<number, TranslatedData>} translatedContent - A record of translated data indexed by a number.
 * @param {Option[]} currentAvailableLanguages - An array of available language options with their labels and IDs.
 * @param {boolean} [includeTranslated=false] - A flag indicating whether to include translated language information.
 * @returns {TranslatedContent[]} - An array of translated contents with optional language labels and codes.
 *
 * @example
 * const translatedContent = {
 *   1: { langCode: 'en', data: { content: { id: '1', name: 'GL Header C1' } } },
 *   2: { langCode: 'fr', data: { content: { id: '2', name: 'GL Header C2' } } }
 * };
 * const availableLanguages = [
 *   { id: "es-ES", label: "Spanish (European)" },
 *   { id: "fr", label: "French (France)" }
 * ];
 *
 * const updatedContents = addTranslatedLanguageLabelForCurrentModule(
 *   translatedContent,
 *   availableLanguages,
 *   true
 * );
 *
 * console.log(updatedContents);
 * // Output:
 * // [
 * //   { id: '1', name: 'GL Header C1', langCode: 'es-ES', langLabel: 'Spanish (European)' },
 * //   { id: '2', name: 'GL Header C2', langCode: 'fr', langLabel: 'French (France)' }
 * // ]
 */
export const addTranslatedLanguageLabelForCurrentModule = (
  translatedContent: Record<number, TranslatedData>,
  currentAvailableLanguages: Option[],
  includeTranslated = false
): TranslatedContent[] => {
  const array = Object.values(translatedContent);

  const translatedContentsArray: TranslatedContent[] = array?.map((item) => {
    if (includeTranslated) {
      const langLabel =
        currentAvailableLanguages?.find((lang) => lang.id === item?.langCode)
          ?.label || '';

      return {
        ...(item?.data?.content as GeneratedModuleContent),
        langCode: item?.langCode,
        langLabel,
      };
    }

    return {
      ...(item?.data?.content as GeneratedModuleContent),
    };
  });

  return translatedContentsArray;
};

// Used for adding translated language label & language code to the `translatedContent` object in the `/generated-content` API response for each Email Module.
/**
 * Adds translated language label and language code to the selected module contents.
 *
 * This function processes two arrays of module contents: one sorted and one translated.
 * It uses a translation configuration to map version sequences to language codes.
 * The function updates the translated contents with language labels and codes based on
 * the current available languages.
 *
 * @param {GeneratedModuleContent[]} sortedContentsArray - An array of module contents sorted by a specific order.
 * @param {GeneratedModuleContent[]} translatedContentsArray - An array of module contents that have been translated.
 * @param {Record<string, string>} translationConfig - A mapping of indices to language codes for translation.
 * @param {Option[]} currentAvailableLanguages - An array of available language options with their labels and IDs.
 * @returns {TranslatedContent[]} - An array of translated contents with added language labels and codes.
 *
 * @example
 * const sortedContents = [
 *   { id: '1', name: 'GL Header C1' },
 *   { id: '2', name: 'GL Header C2' }
 * ];
 * const translatedContents = [
 *   { id: '1', name: 'GL Header C1' },
 *   { id: '2', name: 'GL Header C2' }
 * ];
 * const translationConfig = { "0": "es-ES", "1": "fr" };
 * const availableLanguages = [
 *   { id: "es-ES", label: "Spanish (European)" },
 *   { id: "fr", label: "French (France)" }
 * ];
 *
 * const updatedContents = addTranslatedLanguageLabelForSelectedModule(
 *   sortedContents,
 *   translatedContents,
 *   translationConfig,
 *   availableLanguages
 * );
 *
 * console.log(updatedContents);
 * // Output:
 * // [
 * //   { id: '1', name: 'GL Header C1', langCode: 'es-ES', langLabel: 'Spanish (European)' },
 * //   { id: '2', name: 'GL Header C2', langCode: 'fr', langLabel: 'French (France)' }
 * // ]
 */
export const addTranslatedLanguageLabelForSelectedModule = (
  sortedContentsArray: GeneratedModuleContent[],
  translatedContentsArray: GeneratedModuleContent[],
  translationConfig: Record<string, string>,
  currentAvailableLanguages: Option[]
): TranslatedContent[] => {
  // Create a mapping from content IDs to version sequences using the translation configuration
  const versionSequenceMapping: Record<string, string> = {};

  // Populate the `versionSequenceMapping` with IDs and corresponding language codes
  sortedContentsArray?.forEach((content, index) => {
    const id = get(content, 'id', '');
    if (id) versionSequenceMapping[id] = translationConfig[index];
  });

  // Update the translated contents with language labels and codes
  const updatedTranslatedContentsArray: TranslatedContent[] =
    translatedContentsArray?.map((content) => {
      const translatedContent = { ...content };
      let langLabel = '';
      let langCode = '';

      // Check if the content ID has a corresponding language code in the mapping
      if (
        versionSequenceMapping?.[content?.id] &&
        versionSequenceMapping?.[content?.id].trim() !== ''
      ) {
        // Find the language label based on the language code
        langLabel =
          currentAvailableLanguages?.find(
            (lang) => lang.id === versionSequenceMapping?.[content?.id]
          )?.label || '';

        langCode = versionSequenceMapping?.[content?.id];
      }

      // Return the updated translated content with added language code and label
      return { ...translatedContent, langCode, langLabel };
    });

  return updatedTranslatedContentsArray;
};
