import get from 'lodash/get';
import { ParamsUsed } from '../../../../types/store/markettingContentGeneration/majorProcessActions';
import {
  getEncodedUserNtid,
  getGenerateContentPayload,
  getMappedName,
  getParamsUsed,
  getParentId,
  getVersionIds,
} from '../../helpers/common';
import {
  MarketingFormFieldRequest,
  SecondaryFormApiField,
  VersionPayload,
} from '../../../../types/MarketingBox';
import store from '../../index';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { fetchRegeneratedContent } from '../../../../services/Content';
import { DSPGeneratedContent } from '../../../../components/molecules/DSPRenderer/DSPRenderer.types';
import {
  ANALYTICS,
  ContentType,
  REGENERATION_LIMIT,
  summarizationStatuses,
} from '../../../../constants/common';
import {
  trackConfidenceScore,
  trackConfidenceScoreForPrimaryMessage,
  trackContentConfidenceScore,
  trackGenerateContentComplete,
  trackInputValues,
  trackSecondaryClaims,
  trackSystemErrors,
} from '../../../../services/Analytics';
import { getPrimaryLanguage } from '../../../../services/SSO';
import { preGenerateCliamsStateSegment } from '../../stateSegments/preGenerateClaimsStateSegment';
import { generateStateSegment } from '../../stateSegments/generateStateSegment';
import { currentSelectedLanguageStateSegment } from '../../stateSegments/availableLanguagesStateSegment';
import { translateRefineContent } from './translateRefineContent';
import { getContentGroupId, getSessionId } from '../../../../utils/commonUtils';

export const refineContent = async (
  paramsUsed: ParamsUsed,
  currentVersion: number
) => {
  const generatedContentPayload = getGenerateContentPayload(paramsUsed);

  const parentId = getParentId();
  const versionIds = getVersionIds();

  const refinePayload = {
    toneStyle: getMappedName(
      paramsUsed.currentAvailableToneStyleOptions,
      paramsUsed.currentSelectedToneStyle
    ),
    exclusions: get(
      paramsUsed,
      'currentSelectedExclusion',
      ''
    ) as unknown as string,
    inclusions: (
      get(paramsUsed, 'currentSelectedInclusion', '') as unknown as string
    ).split(','),
  };

  type RegenerateContentPayload = MarketingFormFieldRequest &
    SecondaryFormApiField &
    VersionPayload;
  const requestPayload: RegenerateContentPayload = {
    ...generatedContentPayload,
    ...refinePayload,
    sessionId: getSessionId(),
    contentGroupId: getContentGroupId(),
    parentId,
    versionIds,
  };

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isRefiningContent: true,
      retryPromptParams: paramsUsed,
    };
  });

  const { success, data } = await fetchRegeneratedContent(requestPayload);

  const summarizationStatus = (data?.content as DSPGeneratedContent)
    ?.contentSummary?.summarizationStatus;

  const isDspSummarizationInitiated =
    requestPayload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_INITIATED;

  const isDspError =
    requestPayload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_ERROR;

  if (isDspError) {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isRefiningContent: false,
        errorRefiningContent: true,
        errorMessageGeneratingContent:
          'There has been an error in generating content',
      };
    });

    trackSystemErrors(ANALYTICS.REFINE_FORM_NAME);
    return;
  }

  // create an replica for mutation
  const contentItems = store.get(contentListStateSegment).contentList;
  const currentContent = {
    [getPrimaryLanguage()]: data,
    paramsUsed: { ...getParamsUsed(paramsUsed) },
    regenerationLimit: REGENERATION_LIMIT - 1,
    apiPayloadUsed: requestPayload,
    isRefineApplied: true,
  };
  const contentListReplica = [...contentItems, currentContent];
  if (success && !isDspSummarizationInitiated) {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isRefiningContent: false,
        contentList: [...contentListReplica] as never,
      };
    });

    const { brand, contentType, topics, targetAudience } = requestPayload;
    // Analytics event to log the completion of the form submission
    trackGenerateContentComplete(
      ANALYTICS.REFINE_FORM_NAME,
      brand,
      contentType,
      getEncodedUserNtid()
    );

    const { preFetchedclaimsList } = store.get(preGenerateCliamsStateSegment);
    const isSubstituteClaims = preFetchedclaimsList?.some(
      ({ isSubstitute }) => isSubstitute
    );

    const regenerateInputValues = {
      ...requestPayload,
      ...(isSubstituteClaims
        ? { 'secondary-claims': ANALYTICS.YES }
        : { 'secondary-claims': ANALYTICS.NO }),
      language: getPrimaryLanguage(),
      [ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME]: getEncodedUserNtid(),
    };

    trackInputValues(
      ANALYTICS.REFINE_FORM_NAME,
      ANALYTICS.STEP,
      regenerateInputValues
    );

    if (isSubstituteClaims) {
      trackSecondaryClaims(
        ANALYTICS.SECONDARY_CLAIMS_FORM_NAME,
        brand,
        contentType,
        targetAudience,
        getEncodedUserNtid()
      );
    }

    // track confidence score for regenerate content / brand and primary message
    if (data?.score?.value) {
      trackContentConfidenceScore(
        ANALYTICS.REFINE_FORM_NAME,
        data?.score?.value,
        Object.keys(regenerateInputValues).length + 1 // index for the confidence score question
      );
      trackConfidenceScore(
        ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
        brand,
        getEncodedUserNtid(),
        data?.score?.value
      );
      if (topics) {
        trackConfidenceScoreForPrimaryMessage(
          ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
          topics,
          data?.score?.value
        );
      }
    }
  } else if (success && isDspSummarizationInitiated) {
    const content = data?.content as DSPGeneratedContent;
    store.set(generateStateSegment, {
      contentId: content.id,
      generateContentInitiated: true,
      paramsUsed,
      primaryLanguage: getPrimaryLanguage(),
      apiPayloadUsed: requestPayload,
      isRefineContent: true,
      contentListReplica: [...contentListReplica] as never,
    });
  } else {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isRefiningContent: false,
        errorRefiningContent: true,
        errorMessageGeneratingContent:
          'There has been an error in generating content',
      };
    });

    trackSystemErrors(ANALYTICS.REFINE_FORM_NAME);
  }

  const currentSelectedLanguage = store.get(
    currentSelectedLanguageStateSegment
  );

  if (
    currentSelectedLanguage &&
    currentSelectedLanguage !== getPrimaryLanguage() &&
    success
  ) {
    const { action } = requestPayload;
    translateRefineContent(
      currentVersion,
      currentSelectedLanguage,
      action || '',
      paramsUsed?.currentSelectedDestinationFormat
    );
  }
};
