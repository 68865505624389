import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

export type ImagesData = Record<
  string,
  {
    blobData: Blob;
    dataURI: string;
    attempts: number;
    width: number;
    height: number;
  }
>;

const imagesDataStateSegment = stateSegmentWithReset<ImagesData>({});
imagesDataStateSegment.debugLabel = 'imagesDataStateSegment';

export default imagesDataStateSegment;
