import store from '../../index';
import { contentFeedbackStateSegment } from '../../stateSegments/feedbackSegment';

export const onFeedBackClose = (contentId: string) => {
  const feedbackDefault = {
    isLiked: false,
    isDisliked: false,
    isEnabled: true,
  };

  const additionalFeedbackDefaults = {
    relevance: 0,
    usefulness: 0,
    approvability: 0,
    comment: '',
  };

  store.set(contentFeedbackStateSegment, (prev) => {
    return {
      ...prev,
      [contentId]: {
        ...prev[contentId],
        ...feedbackDefault,
        ...additionalFeedbackDefaults,
        isEnabled: false,
      },
    };
  });
};
