/**
 * @description This file is to track the usage of export content feature
 */

import { ANALYTICS } from '../../constants/common';
import { trackFormInput } from './analyticEvents';

/**
 * @method trackExportToDocUsage
 * @description tracks the usage of export content feature
 * @param {string} brand (brand name)  [brand used to create the content]
 * @param {string} channel (channel name) [channel used to create the content]
 * @param {string} targetAudience (target audience) [target audience used to create the content]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 * @param {boolean} isSuccess [flag to check whether the content export is a success/failure]
 * @param {object} [additionalData] (optional) [additional key-value pairs to track]
 */
export const trackExportToDocUsage = (
  brand: string,
  channel: string,
  targetAudience: string,
  userEncodedNtid: string,
  isSuccess: boolean,
  additionalData?: Record<string, string>
) => {
  const formName = isSuccess
    ? ANALYTICS.EXPORT_TO_DOC_SUCCESS_FORM_NAME
    : ANALYTICS.EXPORT_TO_DOC_ERROR_FORM_NAME;

  trackFormInput(formName, ANALYTICS.NUMBER_ONE, 'brand', brand, '1');
  trackFormInput(formName, ANALYTICS.NUMBER_ONE, 'channel', channel, '2');
  trackFormInput(
    formName,
    ANALYTICS.NUMBER_ONE,
    'targetAudience',
    targetAudience,
    '3'
  );

  if (userEncodedNtid !== '') {
    trackFormInput(
      formName,
      ANALYTICS.NUMBER_ONE,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '4'
    );
  }

  // Track additional data if provided
  if (additionalData) {
    Object.entries(additionalData).forEach(([key, value], index) => {
      trackFormInput(
        formName,
        ANALYTICS.NUMBER_ONE,
        key,
        value,
        (index + 5).toString() // Start index after 4
      );
    });
  }
};
