import { FC } from 'react';
import styles from './PfizerLogo.module.css';

type PfizerLogoProps = {
  onClick: (name: string) => void;
  isExpanded?: boolean;
  className?: string;
};

const PfizerLogo: FC<PfizerLogoProps> = ({
  isExpanded = true,
  onClick,
  className,
}) => {
  const onKeyUpHandler = (
    event: React.KeyboardEvent<SVGElement | HTMLDivElement>
  ) => {
    if (event.key === 'Enter') {
      onClick('pfizer-logo');
    }
  };

  if (!isExpanded) {
    return (
      <div className={styles.collapsed__logo}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          aria-label="Pfizer Logo"
          onClick={() => onClick('pfizer-logo')}
          className={`${styles.logo}  ${className}`}
          tabIndex={0}
          onKeyUp={onKeyUpHandler}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.231697 18.3512C1.28311 15.473 7.26874 14.2601 16.0003 15.2631L17.6917 9.33224C9.05161 8.28565 2.743 9.4631 1.69153 13.2135L0.231697 18.3512Z"
            fill="#0095FF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.7668 12.181C30.7154 15.0592 24.7318 16.2661 16.0003 15.2631L14.309 21.1939C22.9491 22.2405 29.2577 21.063 30.3091 17.3127L31.7668 12.181Z"
            fill="#0000C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.7598 5.93081C18.5142 6.80304 31.5434 12.821 30.3091 17.3127L31.4514 13.3008C31.8172 12.0361 32 11.382 32 10.8586C32 6.27969 19.4285 0.697762 7.45125 0L5.7598 5.93081Z"
            fill="#0095FF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.2402 24.5954C13.4858 23.7232 0.457278 17.7052 1.69153 13.2135L0.548562 17.2255C0.182835 18.4901 0 19.1443 0 19.6676C0 24.2465 12.5715 29.8285 24.5488 30.5263L26.2402 24.5954Z"
            fill="#0000C9"
          />
        </svg>
      </div>
    );
  }
  return (
    <div
      className={`${styles['logo-wrapper']} tab-accessible`}
      aria-label="Pfizer Logo"
      tabIndex={0}
      onKeyUp={onKeyUpHandler}
      onClick={() => onClick('pfizer-logo')}
      role="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="101"
        height="47"
        viewBox="0 0 101 47"
        fill="none"
        className={styles.logo}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.09364 20.0464C5.23476 16.9023 11.7311 15.5773 21.2076 16.673L23.0433 10.1943C13.6661 9.05108 6.8192 10.3373 5.67802 14.4341L4.09364 20.0464Z"
          fill="#0095FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.3192 13.306C37.1781 16.4501 30.684 17.7686 21.2076 16.673L19.372 23.1515C28.7492 24.2947 35.5961 23.0085 36.7372 18.9117L38.3192 13.306Z"
          fill="#0000C9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0939 6.47861C23.9365 7.43141 38.0768 14.0052 36.7372 18.9117L37.9775 14.5293C38.3744 13.1478 38.5728 12.4333 38.5728 11.8616C38.5728 6.85972 24.9287 0.762211 11.9296 0L10.0939 6.47861Z"
          fill="#0095FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.3213 26.8672C18.4788 25.9145 4.33846 19.3406 5.67802 14.4341L4.43773 18.8165C4.04079 20.198 3.84236 20.9126 3.84236 21.4842C3.84236 26.4861 17.4865 32.5837 30.4856 33.3459L32.3213 26.8672Z"
          fill="#0000C9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.7894 18.0485C83.8131 18.0485 84.3363 18.6819 84.3363 20.0798C84.3363 20.2121 84.328 20.3501 84.3117 20.4948H80.3852C80.8113 19.025 81.7234 18.0485 82.7894 18.0485ZM82.8121 25.3435C81.0603 25.3654 80.128 24.2951 80.128 22.395C80.128 22.2694 80.1318 22.1459 80.1375 22.0236H87.0209C87.1571 21.5213 87.2478 20.9752 87.2478 20.4729C87.2478 18.1578 85.7697 16.3886 82.744 16.3886C79.6502 16.3886 76.8975 19.3153 76.8975 22.395C76.8975 25.3435 78.8995 27.1345 82.2435 27.1345C83.7223 27.1345 85.1778 26.654 86.2014 25.9114L85.5648 24.3825C84.7687 24.9722 83.7904 25.3217 82.8121 25.3435Z"
          fill="#0000C9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.5525 19.4463H46.3078L48.0243 13.3745H49.3268C50.9729 13.3745 51.8973 14.4447 51.8973 15.8644C51.8973 18.1359 50.0417 19.4463 47.5525 19.4463ZM55.5598 15.7333C55.5598 13.3309 53.6035 11.5835 50.6462 11.5835H44.9137L40.5915 26.8724H44.2085L45.8015 21.2374H48.9401C52.5343 21.2374 55.5598 19.0096 55.5598 15.7333Z"
          fill="#0000C9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.2726 16.6723L60.6782 15.2526C60.883 14.51 61.2242 13.2432 61.7019 11.7799C62.2023 10.2728 63.1122 9.33364 64.3407 9.33364C64.9321 9.33364 65.2961 9.59576 65.2961 10.0325C65.2733 10.513 64.8639 10.797 64.8639 11.5614C64.8639 12.4569 65.66 13.1558 66.6837 13.1558C67.9579 13.1558 68.9815 12.173 68.9815 10.9717C68.9815 9.11521 67.3206 7.89213 64.7956 7.89213C61.0877 7.89213 58.6536 10.1636 57.1295 15.471L50.942 37.072H54.4452L59.7923 18.354H63.4359L61.0422 26.8722H64.5454L67.4113 16.6723H60.2726Z"
          fill="#0000C9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.0126 16.6726H68.7314L68.2763 18.3543H72.7688L65.7966 26.8725H75.6236L76.0787 25.1907H71.0215L78.0126 16.6726Z"
          fill="#0000C9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.3928 16.4975C95.1371 16.4975 93.8001 17.2764 92.7531 18.8948L93.3671 16.6722H89.8642L86.9974 26.8722H90.5009L91.7975 22.2855C92.3207 20.4071 93.5946 18.5725 94.4362 18.5725C95.3464 18.5725 95.0054 20.2105 96.5749 20.2105C97.7121 20.2105 98.3947 19.2059 98.3947 18.2885C98.3947 17.2183 97.5072 16.4975 96.3928 16.4975Z"
          fill="#0000C9"
        />
        <path
          d="M5.33 46.8682L5.1 45.1882H2.36L1.31 46.8682H0L4.51 39.8682H5.89L6.96 46.8682H5.33ZM2.94 44.2482H4.97L4.6 41.5882L2.94 44.2482Z"
          fill="black"
        />
        <path
          d="M7.74106 46.8682L9.62106 39.8682H11.2111L9.33106 46.8682H7.74106Z"
          fill="black"
        />
        <path
          d="M17.4271 39.7382C18.6971 39.7382 19.5871 40.2482 20.2271 40.9882L19.3571 41.7582C18.8971 41.1582 18.2571 40.7282 17.5671 40.7282C16.1671 40.7282 15.2271 42.0382 15.2271 43.7882C15.2271 45.2282 16.0171 46.0282 17.0871 46.0282C17.7171 46.0282 18.2971 45.7482 18.7871 45.3482L19.3571 46.1682C18.7071 46.6682 17.8871 46.9982 16.8271 46.9982C15.0471 46.9982 13.5871 45.8282 13.5871 43.7882C13.5871 41.5582 15.2771 39.7382 17.4271 39.7382Z"
          fill="black"
        />
        <path
          d="M20.0484 44.8282C20.0484 43.3982 21.1584 42.0782 22.7984 42.0582C24.2384 42.0582 25.0684 42.9682 25.0684 44.2282C25.0684 45.6582 23.9584 46.9782 22.3184 46.9982C20.8784 46.9982 20.0484 46.0882 20.0484 44.8282ZM23.5684 43.9182C23.5684 43.2882 23.3084 42.8682 22.8084 42.8682C22.0384 42.8682 21.5484 44.0682 21.5484 45.1382C21.5484 45.7482 21.7784 46.1882 22.3084 46.1882C23.0784 46.1882 23.5684 44.9882 23.5684 43.9182Z"
          fill="black"
        />
        <path
          d="M25.3122 46.8682L26.5622 42.1982H28.0722L27.8822 42.9182C28.2522 42.4182 28.7922 42.0682 29.3722 42.0682C30.2022 42.0682 30.9522 42.6882 30.5922 44.0282L29.8322 46.8682H28.2922L29.1022 43.8582C29.2422 43.3182 29.0322 43.0082 28.6522 43.0082C28.1622 43.0082 27.7422 43.5282 27.5522 44.2582L26.8522 46.8682H25.3122Z"
          fill="black"
        />
        <path
          d="M34.1542 46.6682C33.8142 46.8582 33.3542 46.9782 32.8942 46.9782C31.9342 46.9782 31.3742 46.4982 31.6642 45.4082L32.3142 42.9682H31.5242L31.7242 42.1982H32.5242L32.8042 41.1282L34.3842 40.9582L34.0542 42.1982H35.1342L34.9342 42.9682H33.8442L33.1342 45.5982C33.0442 45.9282 33.1642 46.1482 33.5142 46.1482C33.6742 46.1482 33.8542 46.1082 34.0042 46.0382L34.1542 46.6682Z"
          fill="black"
        />
        <path
          d="M37.4303 46.9982C35.9803 46.9982 35.0303 46.2082 35.0303 44.8382C35.0303 43.3982 36.1103 42.0682 37.6803 42.0682C39.0003 42.0682 39.7303 42.8782 39.7303 44.0282C39.7303 44.2182 39.6903 44.5182 39.6503 44.7082H36.5403C36.5303 45.7282 36.9903 46.1682 37.7203 46.1682C38.1603 46.1682 38.5803 45.9982 38.9103 45.7482L39.2803 46.4282C38.8303 46.7682 38.1603 46.9982 37.4303 46.9982ZM37.6903 42.8582C37.1703 42.8582 36.7703 43.3182 36.6103 43.9582H38.3903V43.8482C38.3903 43.1882 38.1603 42.8582 37.6903 42.8582Z"
          fill="black"
        />
        <path
          d="M39.981 46.8682L41.231 42.1982H42.741L42.551 42.9182C42.921 42.4182 43.461 42.0682 44.041 42.0682C44.871 42.0682 45.621 42.6882 45.261 44.0282L44.501 46.8682H42.961L43.771 43.8582C43.911 43.3182 43.701 43.0082 43.321 43.0082C42.831 43.0082 42.411 43.5282 42.221 44.2582L41.521 46.8682H39.981Z"
          fill="black"
        />
        <path
          d="M48.823 46.6682C48.483 46.8582 48.023 46.9782 47.563 46.9782C46.603 46.9782 46.043 46.4982 46.333 45.4082L46.983 42.9682H46.193L46.393 42.1982H47.193L47.473 41.1282L49.053 40.9582L48.723 42.1982H49.803L49.603 42.9682H48.513L47.803 45.5982C47.713 45.9282 47.833 46.1482 48.183 46.1482C48.343 46.1482 48.523 46.1082 48.673 46.0382L48.823 46.6682Z"
          fill="black"
        />
        <path
          d="M55.5177 46.9982C53.4277 46.9982 52.1077 45.5982 52.1077 43.8182C52.0977 41.5282 53.8677 39.7482 56.0677 39.7482C57.5177 39.7482 58.4077 40.2882 59.0677 41.0382L58.2177 41.7982C57.7177 41.1082 56.9877 40.7582 56.1677 40.7582C54.7377 40.7582 53.7577 42.1082 53.7577 43.7882C53.7577 45.1882 54.5877 46.0082 55.7877 46.0082C56.1277 46.0082 56.5277 45.9182 56.8977 45.7382L57.3177 44.1882H55.9577L56.2277 43.2382H59.1177L58.3477 46.0682C57.6477 46.5682 56.6377 46.9982 55.5177 46.9982Z"
          fill="black"
        />
        <path
          d="M62.259 46.9982C60.809 46.9982 59.859 46.2082 59.859 44.8382C59.859 43.3982 60.939 42.0682 62.509 42.0682C63.829 42.0682 64.559 42.8782 64.559 44.0282C64.559 44.2182 64.519 44.5182 64.479 44.7082H61.369C61.359 45.7282 61.819 46.1682 62.549 46.1682C62.989 46.1682 63.409 45.9982 63.739 45.7482L64.109 46.4282C63.659 46.7682 62.989 46.9982 62.259 46.9982ZM62.519 42.8582C61.999 42.8582 61.599 43.3182 61.439 43.9582H63.219V43.8482C63.219 43.1882 62.989 42.8582 62.519 42.8582Z"
          fill="black"
        />
        <path
          d="M64.8097 46.8682L66.0597 42.1982H67.5697L67.3797 42.9182C67.7497 42.4182 68.2897 42.0682 68.8697 42.0682C69.6997 42.0682 70.4497 42.6882 70.0897 44.0282L69.3297 46.8682H67.7897L68.5997 43.8582C68.7397 43.3182 68.5297 43.0082 68.1497 43.0082C67.6597 43.0082 67.2397 43.5282 67.0497 44.2582L66.3497 46.8682H64.8097Z"
          fill="black"
        />
        <path
          d="M73.2393 46.9982C71.7893 46.9982 70.8393 46.2082 70.8393 44.8382C70.8393 43.3982 71.9193 42.0682 73.4893 42.0682C74.8093 42.0682 75.5393 42.8782 75.5393 44.0282C75.5393 44.2182 75.4993 44.5182 75.4593 44.7082H72.3493C72.3393 45.7282 72.7993 46.1682 73.5293 46.1682C73.9693 46.1682 74.3893 45.9982 74.7193 45.7482L75.0893 46.4282C74.6393 46.7682 73.9693 46.9982 73.2393 46.9982ZM73.4993 42.8582C72.9793 42.8582 72.5793 43.3182 72.4193 43.9582H74.1993V43.8482C74.1993 43.1882 73.9693 42.8582 73.4993 42.8582Z"
          fill="black"
        />
        <path
          d="M79.88 42.0982C80.49 42.0982 80.76 42.4582 80.76 42.8682C80.76 43.2882 80.42 43.7282 79.95 43.7282C79.36 43.7282 79.35 43.1482 78.99 43.1482C78.65 43.1482 78.15 43.7482 77.92 44.6182L77.33 46.8682H75.79L77.05 42.1982H78.59L78.34 43.1182C78.81 42.3482 79.35 42.0982 79.88 42.0982Z"
          fill="black"
        />
        <path
          d="M83.3838 46.8682L83.5838 46.1282C83.1738 46.6482 82.5538 46.9982 81.9238 46.9982C81.1938 46.9982 80.6938 46.5182 80.6938 45.8282C80.6938 44.7882 81.6138 44.3282 82.7338 44.2282L83.2838 44.1782C84.0238 44.1082 84.2338 43.7482 84.2338 43.4182C84.2338 42.9582 83.8338 42.8182 83.4238 42.8182C82.9238 42.8182 82.6038 43.0182 82.5238 43.2782C82.4938 43.3682 82.4938 43.4682 82.4538 43.5882C82.3838 43.7982 82.1338 43.9782 81.8238 43.9782C81.4838 43.9782 81.2738 43.7682 81.2738 43.4482C81.2738 42.8782 82.0438 42.0582 83.6238 42.0582C84.9738 42.0582 85.9638 42.7082 85.5938 44.1482L84.8738 46.8682H83.3838ZM82.1338 45.6082C82.1338 45.9382 82.3938 46.1182 82.7138 46.1182C83.1638 46.1182 83.6338 45.7482 83.8238 45.2182L83.9538 44.6982C83.7738 44.8182 83.5438 44.8582 83.3038 44.8782L82.9038 44.9182C82.3338 44.9782 82.1338 45.2682 82.1338 45.6082Z"
          fill="black"
        />
        <path
          d="M89.17 46.6682C88.83 46.8582 88.37 46.9782 87.91 46.9782C86.95 46.9782 86.39 46.4982 86.68 45.4082L87.33 42.9682H86.54L86.74 42.1982H87.54L87.82 41.1282L89.4 40.9582L89.07 42.1982H90.15L89.95 42.9682H88.86L88.15 45.5982C88.06 45.9282 88.18 46.1482 88.53 46.1482C88.69 46.1482 88.87 46.1082 89.02 46.0382L89.17 46.6682Z"
          fill="black"
        />
        <path
          d="M90.0161 44.8282C90.0161 43.3982 91.1261 42.0782 92.7661 42.0582C94.2061 42.0582 95.0361 42.9682 95.0361 44.2282C95.0361 45.6582 93.9261 46.9782 92.2861 46.9982C90.8461 46.9982 90.0161 46.0882 90.0161 44.8282ZM93.5361 43.9182C93.5361 43.2882 93.2761 42.8682 92.7761 42.8682C92.0061 42.8682 91.5161 44.0682 91.5161 45.1382C91.5161 45.7482 91.7461 46.1882 92.2761 46.1882C93.0461 46.1882 93.5361 44.9882 93.5361 43.9182Z"
          fill="black"
        />
        <path
          d="M99.3699 42.0982C99.9799 42.0982 100.25 42.4582 100.25 42.8682C100.25 43.2882 99.9099 43.7282 99.4399 43.7282C98.8499 43.7282 98.8399 43.1482 98.4799 43.1482C98.1399 43.1482 97.6399 43.7482 97.4099 44.6182L96.8199 46.8682H95.2799L96.5399 42.1982H98.0799L97.8299 43.1182C98.2999 42.3482 98.8399 42.0982 99.3699 42.0982Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

PfizerLogo.displayName = 'PfizerLogo';
export default PfizerLogo;
