import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

export type FileUploads = {
  isLoading: boolean;
  fileName: string;
  fileUploaded: boolean;
  fileUploading: boolean;
  fileUploadError: boolean;
  fileProcessing: boolean;
  fileProcessed: boolean;
  fileProcessedError: boolean;
  fileProcessingError: boolean;
  fileDeleting: boolean;
  fileDeleted: boolean;
  sessionId: string;
  error: string | null;
  success: string | null;
  fileUploadPercentage: number;
  sessionLoading: boolean;
  sessionLoaded: boolean;
};

export const fileUploadSegment = stateSegmentWithReset<FileUploads>({
  isLoading: false,
  fileName: '',
  fileUploaded: false,
  fileUploading: false,
  fileUploadError: false,
  fileProcessing: false,
  fileProcessed: false,
  fileProcessedError: false,
  fileProcessingError: false,
  sessionId: '',
  error: null,
  success: null,
  fileUploadPercentage: 0,
  fileDeleting: false,
  fileDeleted: false,
  sessionLoading: false,
  sessionLoaded: false,
});

fileUploadSegment.debugLabel = 'fileUploadSegment';
