/**
 * @description This file has methods to trigger analytic events for generate/regenerate content feature
 */

import { ANALYTICS } from '../../constants/common';
import { PaloadValue } from '../../types/Analytics';
import {
  LabelPayload,
  MarketingFormFieldRequest,
} from '../../types/MarketingBox';
import { trackFormInput, trackFormWorkFlow } from './analyticEvents';

const extractString = (value: PaloadValue) => {
  if (Array.isArray(value)) {
    return value.join(',');
  }
  if (typeof value === 'string') {
    return value;
  }
  return '';
};

/**
 * @method trackInputValues
 * @description This method iterates over the payload submitted with the form and tracks individual form input values
 * @param {string} formName (form name) [name of the form]
 * @param {string} stepNumber (step number) [for pagination, set to 1 if only one page]
 * @param {LabelPayload | MarketingFormField} payload (payload) [payload data submitted with the form to an API - form input values]
 */
export const trackInputValues = (
  formName: string,
  stepNumber: string,
  payload:
    | LabelPayload
    | (MarketingFormFieldRequest & {
        language: string;
        'secondary-claims': string;
      })
) => {
  Object.entries(payload).forEach(([key, value], index) => {
    const label = extractString(value as string | string[]);
    trackFormInput(formName, stepNumber, key, label, (index + 1).toString());
  });
};

/**
 * @method trackGenerateContentInitiate
 * @description this method tracks the generate form initiation
 * @param {string} formName (form name) [name of the form]
 */
export const trackGenerateContentInitiate = (formName: string) => {
  // Form initiation tracking
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.INITIATED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );
};

/**
 * @method trackGenerateContentSubmit
 * @description this method tracks the generate form submission
 * @param {string} formName (form name) [name of the form]
 * @param {string} brand (selected brand name) [name of the brand selected in the prompt form]
 * @param {string} channel (selected channel name) [name of the channel selected in the prompt form]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 */
export const trackGenerateContentSubmit = (
  formName: string,
  brand: string,
  channel: string,
  userEncodedNtid: string
) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.SUBMITTED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );
  trackFormInput(
    `${formName}-submit`,
    ANALYTICS.STEP,
    'brand',
    `${brand}`,
    '1'
  );
  trackFormInput(
    `${formName}-submit`,
    ANALYTICS.STEP,
    'channel',
    `${channel}`,
    '2'
  );

  if (userEncodedNtid !== '') {
    trackFormInput(
      `${formName}-submit`,
      ANALYTICS.STEP,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '3'
    );
  }
};

/**
 * @method trackGenerateContentComplete
 * @description this method tracks the completion of generate form submission - Success of the API
 * @param {string} formName (form name) [name of the form]
 * @param {string} brand (selected brand name) [name of the brand selected in the prompt form]
 * @param {string} channel (selected channel name) [name of the channel selected in the prompt form]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 */
export const trackGenerateContentComplete = (
  formName: string,
  brand?: string,
  channel?: string,
  userEncodedNtid?: string
) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.COMPLETED,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    ''
  );

  if (brand && channel) {
    trackFormInput(
      `${formName}-complete`,
      ANALYTICS.STEP,
      'brand',
      `${brand}`,
      '1'
    );
    trackFormInput(
      `${formName}-complete`,
      ANALYTICS.STEP,
      'channel',
      `${channel}`,
      '2'
    );
  }

  if (userEncodedNtid && userEncodedNtid !== '') {
    trackFormInput(
      `${formName}-complete`,
      ANALYTICS.STEP,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '3'
    );
  }
};

/**
 * @method trackSystemErrors
 * @description this method tracks the system errors from the APIs
 * @param {string} formName (form name) [name of the form]
 */
export const trackSystemErrors = (formName: string) => {
  trackFormWorkFlow(
    formName,
    ANALYTICS.FORM_ACTION.ERROR,
    ANALYTICS.STEP,
    ANALYTICS.PAGE_LOAD,
    'There has been an error in generating content. The APIs are not able to generate the content now.'
  );
};
