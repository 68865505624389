/**
 * Encodes a string to Base64 format, handling both ASCII and non-ASCII characters.
 * Applies proper encoding based on character type.
 *
 * @param {string} inputString - The string to be encoded to Base64.
 * @returns {string} - The Base64 encoded string.
 * @throws Will throw an error if encoding fails.
 */
export function encodeToBase64(inputString: string): string {
  try {
    // Use TextEncoder to handle UTF-8 encoding
    const utf8Bytes = new TextEncoder().encode(inputString);
    // Convert the byte array to a string of characters
    const binaryString = String.fromCharCode(...utf8Bytes);
    // Encode the binary string to Base64
    return btoa(binaryString);
  } catch (error) {
    throw new Error('Failed to encode string to Base64');
  }
}

/**
 * Decodes a Base64 encoded string, handling both ASCII and non-ASCII characters.
 *
 * @param {string} base64String - The Base64 encoded string to be decoded.
 * @returns {string} - The decoded string.
 * @throws Will throw an error if decoding fails.
 */
export function decodeFromBase64(base64String: string): string {
  try {
    // Decode the Base64 string to a binary string
    const binaryString = atob(base64String);
    // Convert the binary string to a byte array
    const utf8Bytes = Uint8Array.from(binaryString, (char) =>
      char.charCodeAt(0)
    );
    // Use TextDecoder to decode the UTF-8 byte array
    return new TextDecoder().decode(utf8Bytes);
  } catch (error) {
    throw new Error('Failed to decode Base64 string');
  }
}
