import { translate } from '../../../localization';
import styles from './AccessForbidden.module.css';

const AccessForbidden = () => {
  return (
    <div className={styles['access-forbidden__page-wrapper']}>
      <div className={styles['access-forbidden__page-content']}>
        <p>{translate('access_forbidden_message_one')}</p>
        <p>{translate('access_forbidden_message_two')}</p>
      </div>
    </div>
  );
};

AccessForbidden.displayName = 'AccessForbidden';
export default AccessForbidden;
