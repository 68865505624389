const ContentLoadingAnimation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g clipPath="url(#clip0_1792_14911)">
      <rect width="32" height="32" fill="none" />
      <circle
        cx="16"
        cy="16"
        r="14"
        transform="rotate(-180 16 16)"
        stroke="#F0EFEE"
        strokeWidth="4"
      />
      <path
        d="M30 16C30 23.732 23.732 30 16 30"
        stroke="#0000C9"
        strokeWidth="4"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          keyTimes="0;1"
          values="0 16 16;360 16 16"
        />
      </path>
    </g>
    <defs>
      <clipPath id="clip0_1792_14911">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

ContentLoadingAnimation.displayName = 'ContentLoadingAnimation';
export default ContentLoadingAnimation;
