import store from '../../index';
import { contentTranslationStateSegment } from '../../stateSegments/translationSegment';

export const clearTranslationErrors = () => {
  store.set(contentTranslationStateSegment, (prev) => {
    return {
      ...prev,
      isTranslatingContent: false,
      isPartialTranslating: false,
      errorTranslatingContent: false,
      errorPartialTranslating: false,
      errorMessage: '',
    };
  });
};
