import store from '../../index';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';

export const updateCompareMode = (isCompareModeOn: boolean) => {
  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isCompareModeOn,
    };
  });
};
