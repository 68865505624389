const Logger = (() => {
  // Private variables and functions
  let isDev = process.env.NODE_ENV === 'development';

  const logToConsole = (...message: unknown[]) => {
    if (isDev) {
      /* eslint-disable */
      console.log(...message);
    }
  };

  const logErrorToConsole = (error: Error) => {
    if (isDev) {
      console.error('[ERROR]', error.message);
    }
  };

  // Public API
  return {
    enableDevLogging: () => {
      isDev = true;
    },

    disableDevLogging: () => {
      isDev = false;
    },

    info: (message: unknown) => {
      logToConsole('[INFO]', message);
    },

    warn: (message: unknown) => {
      logToConsole('[WARN]', message);
    },

    error: (error: Error) => {
      logErrorToConsole(error);
    },
  };
})();

export default Logger;
