import {
  DEPLOYED_ENV_NAME_DEV,
  DEPLOYED_ENV_NAME_UAT,
  DEPLOYED_ENV_NAME_PROD,
} from '../constants/deployedEnvNames';

type DeployedEnvironments = {
  [key: string]: string;
  dev: string;
  uat: string;
  prod: string;
};

const deployedEnvNames: DeployedEnvironments = {
  dev: DEPLOYED_ENV_NAME_DEV,
  uat: DEPLOYED_ENV_NAME_UAT,
  prod: DEPLOYED_ENV_NAME_PROD,
};

export function getEnvironmentName(): string {
  if (process.env.NODE_ENV === 'development') {
    return '';
  }
  if (process.env.REACT_APP_ENVIRONMENT) {
    return deployedEnvNames[process.env.REACT_APP_ENVIRONMENT];
  }
  return deployedEnvNames.prod;
}
