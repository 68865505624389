import store from '../../index';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { preGenerateCliamsStateSegment } from '../../stateSegments/preGenerateClaimsStateSegment';

export const clearErrors = () => {
  // clear prefetched claims error also
  store.set(preGenerateCliamsStateSegment, (prev) => {
    return {
      ...prev,
      isPrefetchedClaimsError: false,
    };
  });

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      errorGeneratingContent: false,
      errorReGeneratingContent: false,
      errorRefiningContent: false,
      errorMessage: '',
    };
  });
};
