import toLower from 'lodash/toLower';
import lowerCase from 'lodash/lowerCase';

export function convertTextToLowerCase(
  text: string = '',
  persistSpecialChars: boolean = true
): string {
  if (!text) {
    return '';
  }

  if (persistSpecialChars) {
    return toLower(text);
  }
  return lowerCase(text);
}
