import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

export type FeedbackData = {
  isLiked: boolean;
  isDisliked: boolean;
  isEnabled: boolean;
  relevance?: number;
  usefulness?: number;
  approvability?: number;
  comment?: string;
};

type FeedbackHashmap = Record<string, FeedbackData>;

export const contentFeedbackStateSegment =
  stateSegmentWithReset<FeedbackHashmap>({});
contentFeedbackStateSegment.debugLabel = 'contentFeedbackStateSegment';
