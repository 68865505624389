import React from 'react';
import styles from './NavSection.module.css';
import NavMenuItem from '../../atoms/NavMenuItem';
import { translate } from '../../../localization';

interface NavSectionItem {
  icon: React.ReactNode;
  text: string;
  name: string;
}

interface NavSectionProps {
  items: NavSectionItem[];
  isExpanded: boolean;
  onNavMenuClicked: (name: string) => void;
  className?: string;
  activeMenu?: string;
}

const NavSection: React.FC<NavSectionProps> = ({
  items,
  isExpanded,
  onNavMenuClicked,
  className,
  activeMenu = '',
}) => (
  <div className={`${styles['nav-section']} ${className}`}>
    <ul className={styles['menu-list']}>
      {items.map((item) => (
        <NavMenuItem
          key={item.name}
          icon={item.icon}
          text={item.text}
          isExpanded={isExpanded}
          isActive={activeMenu === item.name}
          handleMenuItemClick={() => onNavMenuClicked(item.name)}
          isBetaFeature={
            item?.text?.toLowerCase() === translate('upload').toLowerCase()
          } // Needs to be passed till "Upload" is a beta feature
        />
      ))}
    </ul>
  </div>
);

export default NavSection;
