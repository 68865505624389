import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import { ExtractionDataType } from '../../../types/ExtractionDetails';

export const extractionDetailsSegment =
  stateSegmentWithReset<ExtractionDataType>({
    fileUpload: {
      totalPages: 0,
      brand: null,
      country: null,
      topics: [],
    },
  });

extractionDetailsSegment.debugLabel = 'extractionDetailsSegment';
