import React, { FC, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import styles from './Tooltip.styles.module.css';

export enum TooltipVariant {
  TOP_RIGHT = 'TOP_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  TOP_CENTER = 'TOP_CENTER',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM_CENTER = 'BOTTOM_CENTER',
}

export enum ToolTipSize {
  REGULAR = 'regular',
  LARGE = 'large',
}

const toolTipSizeClassMap = {
  [ToolTipSize.REGULAR]: styles['tooltip__text-regular'],
  [ToolTipSize.LARGE]: styles['tooltip__text-large'],
};

const tooltipVariantClassMap = {
  [TooltipVariant.TOP_RIGHT]: styles['tooltip__text--top-right'],
  [TooltipVariant.TOP_LEFT]: styles['tooltip__text--top-left'],
  [TooltipVariant.TOP_CENTER]: styles['tooltip__text--top-center'],
  [TooltipVariant.BOTTOM_LEFT]: styles['tooltip__text--bottom-left'],
  [TooltipVariant.BOTTOM_RIGHT]: styles['tooltip__text--bottom-right'],
  [TooltipVariant.BOTTOM_CENTER]: styles['tooltip__text--bottom-center'],
};

interface TooltipProps {
  text: string | JSX.Element;
  children: React.ReactNode;
  showTooltip: boolean;
  variant?: TooltipVariant;
  onClickedOutside: () => void;
  theme?: string;
  size?: ToolTipSize;
  id: string;
  classes?: string;
}

const Tooltip: FC<TooltipProps> = ({
  text,
  children,
  showTooltip,
  variant = TooltipVariant.TOP_LEFT,
  onClickedOutside,
  theme = 'default',
  size = ToolTipSize.REGULAR,
  id,
  classes: className = '',
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const mappedVariantClass = tooltipVariantClassMap[variant];
  const mappedSizeClass = toolTipSizeClassMap[size];

  const visibilityClass = !showTooltip ? styles['tooltip__text--hidden'] : '';
  const classes = `${
    styles.tooltip__text
  } ${visibilityClass} ${mappedVariantClass} ${mappedSizeClass} ${
    styles[`tooltip-theme--${theme}`]
  } ${className}`;

  useOnClickOutside(ref, onClickedOutside);
  return (
    <div className={styles.tooltip} ref={ref} aria-describedby={id}>
      {children}
      <div className={classes} role="tooltip" aria-hidden={showTooltip} id={id}>
        {text}
      </div>
    </div>
  );
};

Tooltip.displayName = 'Tooltip';
export default Tooltip;
