export const HeaderC1Icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 6H0V8H64V6ZM0 12H48V14H0V12ZM64 18H0V19H64V18ZM64 23H0V24H64V23ZM27 59.5H36.2362V60.5H27V59.5ZM25 57H38.2597V63H25V57ZM24 56H25H38.2597H39.2597V57V63V64H38.2597H25H24V63V57V56ZM63 29H1V51H63V29ZM1 28H0V29V51V52H1H63H64V51V29V28H63H1ZM28.404 44.51L28.414 44.5H36.504C36.6353 44.499 36.7609 44.4463 36.8537 44.3534C36.9465 44.2605 36.9991 44.1348 37 44.0035V35.9965C37 35.7225 36.778 35.5 36.504 35.5H27.496C27.3647 35.501 27.2391 35.5537 27.1463 35.6466C27.0534 35.7395 27.0009 35.8652 27 35.9965V44.0035C27 44.1351 27.0522 44.2613 27.1452 44.3544C27.2382 44.4475 27.3644 44.4999 27.496 44.5H28.3935L28.404 44.51ZM36 36.5V41.5L33 38.5L28 43.5V36.5H36ZM33 39.914L36 42.914V43.5H29.414L33 39.914ZM29.2929 39.2071C29.4804 39.3946 29.7348 39.5 30 39.5C30.2652 39.5 30.5196 39.3946 30.7071 39.2071C30.8946 39.0196 31 38.7652 31 38.5C31 38.2348 30.8946 37.9804 30.7071 37.7929C30.5196 37.6054 30.2652 37.5 30 37.5C29.7348 37.5 29.4804 37.6054 29.2929 37.7929C29.1054 37.9804 29 38.2348 29 38.5C29 38.7652 29.1054 39.0196 29.2929 39.2071Z"
      fill="#18181B"
    />
  </svg>
);
