import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import { getPrimaryLanguage } from '../../../services/SSO';
import { Option } from '../../../types/SelectInput';

export const currentLanguageTabsListStateSegment = stateSegmentWithReset<
  Option[]
>([]);

currentLanguageTabsListStateSegment.debugLabel =
  'currentLanguageTabsListStateSegment';

export const currentLanguageTabStateSegment = stateSegmentWithReset<string>(
  getPrimaryLanguage()
);

currentLanguageTabStateSegment.debugLabel = 'currentLanguageTabStateSegment';
