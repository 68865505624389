import store from '../../index';
import {
  MarketingFormFieldRequest,
  SecondaryFormApiField,
  VersionPayload,
} from '../../../../types/MarketingBox';
import { ParamsUsed } from '../../../../types/store/markettingContentGeneration/majorProcessActions';
import { getContentGroupId, getSessionId } from '../../../../utils/commonUtils';
import {
  getEncodedUserNtid,
  getGenerateContentPayload,
  getParamsUsed,
  getParentId,
  getRefinementAdditionalParams,
  getVersionIds,
  resetTranslationState,
} from '../../helpers/common';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { fetchRegeneratedContent } from '../../../../services/Content';
import { DSPGeneratedContent } from '../../../../components/molecules/DSPRenderer/DSPRenderer.types';
import {
  ANALYTICS,
  ContentType,
  REGENERATION_LIMIT,
  summarizationStatuses,
} from '../../../../constants/common';
import {
  trackConfidenceScore,
  trackConfidenceScoreForPrimaryMessage,
  trackContentConfidenceScore,
  trackGenerateContentComplete,
  trackInputValues,
  trackSecondaryClaims,
  trackSystemErrors,
} from '../../../../services/Analytics';
import { preGenerateCliamsStateSegment } from '../../stateSegments/preGenerateClaimsStateSegment';
import { getPrimaryLanguage } from '../../../../services/SSO';
import { moduleContentsStateSegment } from '../../stateSegments/emailModulesStateSegment';
import { generateStateSegment } from '../../stateSegments/generateStateSegment';

export const regenerateContent = async (paramsUsed: ParamsUsed) => {
  resetTranslationState();

  const generatedContentPayload = getGenerateContentPayload(paramsUsed);
  const refinePayload = getRefinementAdditionalParams(paramsUsed);

  const parentId = getParentId();
  const versionIds = getVersionIds();

  type RegenerateContentPayload = MarketingFormFieldRequest &
    SecondaryFormApiField &
    VersionPayload & { sessionId: string };
  const requestPayload: RegenerateContentPayload = {
    ...generatedContentPayload,
    ...refinePayload,
    sessionId: getSessionId(),
    contentGroupId: getContentGroupId(),
    parentId,
    versionIds,
  };

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isReGeneratingContent: true,
      retryPromptParams: paramsUsed,
    };
  });

  const { success, data } = await fetchRegeneratedContent(requestPayload);

  const summarizationStatus = (data?.content as DSPGeneratedContent)
    ?.contentSummary?.summarizationStatus;

  const isDspSummarizationInitiated =
    requestPayload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_INITIATED;

  const isDspError =
    requestPayload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_ERROR;

  if (isDspError) {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isReGeneratingContent: false,
        errorReGeneratingContent: true,
        errorMessageGeneratingContent:
          'There has been an error in generating content',
      };
    });
    trackSystemErrors(ANALYTICS.REGENERATE_FORM_NAME);
    return;
  }

  function trackAnalytics() {
    const { brand, contentType, topics, targetAudience, destinationFormat } =
      requestPayload;
    const { preFetchedclaimsList } = store.get(preGenerateCliamsStateSegment);

    const isSubstituteClaims = preFetchedclaimsList?.some(
      ({ isSubstitute }) => isSubstitute
    );

    const regenerateInputValues = {
      ...requestPayload,
      ...(isSubstituteClaims
        ? { 'secondary-claims': ANALYTICS.YES }
        : { 'secondary-claims': ANALYTICS.NO }),
      language: getPrimaryLanguage(),
      [ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME]: getEncodedUserNtid(),
    };

    const channel =
      (contentType === ContentType.DSP && destinationFormat) || contentType;

    trackInputValues(
      ANALYTICS.REGENERATE_FORM_NAME,
      ANALYTICS.STEP,
      regenerateInputValues
    );

    if (isSubstituteClaims) {
      trackSecondaryClaims(
        ANALYTICS.SECONDARY_CLAIMS_FORM_NAME,
        brand,
        contentType,
        targetAudience,
        getEncodedUserNtid()
      );
    }

    // track confidence score for regenerate content / brand and primary message
    if (data?.score?.value) {
      trackContentConfidenceScore(
        ANALYTICS.REGENERATE_FORM_NAME,
        data?.score?.value,
        Object.keys(regenerateInputValues).length + 1 // index for the confidence score question
      );
      trackConfidenceScore(
        ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
        brand,
        getEncodedUserNtid(),
        data?.score?.value
      );
      if (topics) {
        trackConfidenceScoreForPrimaryMessage(
          ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
          topics,
          data?.score?.value
        );
      }
    }

    if (channel.toLowerCase() !== ContentType.DSP.toLowerCase()) {
      trackGenerateContentComplete(
        ANALYTICS.REGENERATE_FORM_NAME,
        brand,
        contentType,
        getEncodedUserNtid()
      );
    }
  }

  if (success && !isDspSummarizationInitiated) {
    const { contentType, destinationFormat, parentId: id } = requestPayload;

    if (
      contentType === 'email' ||
      destinationFormat?.toLowerCase() === 'email'
    ) {
      const moduleContents = new Map(
        Array.from(store.get(moduleContentsStateSegment)).values()
      );
      const moduleContent = Array.from(moduleContents.values()).find(
        (module) => module.parentContentId === id
      );
      if (moduleContent) {
        const { mapKey } = moduleContent;
        const { translationConfig } = moduleContent;
        moduleContents.set(mapKey, {
          ...moduleContent,
          translationConfig: {
            ...translationConfig,
            [Object.keys(translationConfig).length]: getPrimaryLanguage(),
          },
        });
        store.set(moduleContentsStateSegment, moduleContents);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store.set(contentListStateSegment, (prev: any) => {
      const wasLastContentRefined =
        prev.contentList[prev.contentList.length - 1].isRefineApplied;
      const content = {
        [getPrimaryLanguage()]: data,
        paramsUsed: getParamsUsed(paramsUsed),
        regenerationLimit: REGENERATION_LIMIT - 1,
        apiPayloadUsed: requestPayload,
        isRefineApplied: wasLastContentRefined,
      };

      return {
        ...prev,
        isReGeneratingContent: false,
        errorReGeneratingContent: false,
        errorMessage: '',
        isGeneratingContent: false,
        contentList: [...prev.contentList, content] as never,
      };
    });

    trackAnalytics();
  } else if (success && isDspSummarizationInitiated) {
    const content = data?.content as DSPGeneratedContent;
    store.set(generateStateSegment, {
      contentId: content.id,
      generateContentInitiated: true,
      paramsUsed,
      primaryLanguage: getPrimaryLanguage(),
      apiPayloadUsed: requestPayload,
      isReGeneratingContent: true,
    });

    trackAnalytics();
  } else {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isReGeneratingContent: false,
        errorReGeneratingContent: true,
        errorMessageGeneratingContent:
          'There has been an error in generating content',
      };
    });
    trackSystemErrors(ANALYTICS.REGENERATE_FORM_NAME);
  }
};
