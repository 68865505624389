/**
 * @description This file is to track the usage of download image feature
 */

import { ANALYTICS } from '../../constants/common';
import { trackFormInput } from './analyticEvents';

/**
 * @method trackDownloadImageUsage
 * @description tracks the usage of image download feature
 * @param {string} brand (brand name)  [brand used to create the content]
 * @param {string} channel (channel name) [channel used to create the content]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 * @param {object} [additionalData] (optional) [additional key-value pairs to track]
 */
export const trackDownloadImageUsage = (
  brand: string,
  channel: string,
  userEndodedNtid: string,
  additionalData?: Record<string, string>
) => {
  // Track brand
  trackFormInput(
    ANALYTICS.DOWNLOAD_IMAGES_FORM_NAME,
    ANALYTICS.NUMBER_ONE,
    'brand',
    brand,
    '1'
  );

  // Track channel
  trackFormInput(
    ANALYTICS.DOWNLOAD_IMAGES_FORM_NAME,
    ANALYTICS.NUMBER_ONE,
    'channel',
    channel,
    '2'
  );

  // track user encode ntid
  if (userEndodedNtid !== '') {
    trackFormInput(
      ANALYTICS.DOWNLOAD_IMAGES_FORM_NAME,
      ANALYTICS.NUMBER_ONE,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEndodedNtid,
      '3'
    );
  }

  // Track additional data if provided
  if (additionalData) {
    Object.entries(additionalData).forEach(([key, value], index) => {
      trackFormInput(
        ANALYTICS.DOWNLOAD_IMAGES_FORM_NAME,
        ANALYTICS.NUMBER_ONE,
        key,
        value,
        (index + 4).toString() // Start index after 3
      );
    });
  }
};
