import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import { ContentTypeImage } from '../../../types/Content';

type Data = {
  isLoading: boolean;
  errorFetchingImages: boolean;
  imagesReferenceFrame: string;
  images: ContentTypeImage[];
};

type OptionIdsHashMap = Record<string, Data>;

export type ContentImagesHashMap = Record<string, OptionIdsHashMap>;

export const contentImagesStateSegment =
  stateSegmentWithReset<ContentImagesHashMap>({});
contentImagesStateSegment.debugLabel = 'contentImagesStateSegment';
