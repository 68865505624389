import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

export type RefineOptions = {
  currentSelectedToneStyle?: string;
  currentSelectedExclusion?: string;
  currentSelectedInclusion?: string;
};

export const refineOptionsStateSegment = stateSegmentWithReset<RefineOptions>({
  currentSelectedToneStyle: '',
  currentSelectedExclusion: '',
  currentSelectedInclusion: '',
});

refineOptionsStateSegment.debugLabel = 'refineOptionsStateSegment';
