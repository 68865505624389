import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from 'axios';
import get from 'lodash/get';
// import { BASE_URL } from '../constants/apiRoutes';
import { getAPIGatewayEndpoint } from './getAPIGatewayEndpoint';

class HttpClient {
  private apiInstance: AxiosInstance;

  private dataExtractor: string;

  constructor({ baseURL = '', dataExtractor = 'data.data' }) {
    this.apiInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.dataExtractor = dataExtractor;
  }

  private async request<T>(
    method: 'get' | 'post' | 'delete' | 'patch' | 'put',
    url: string,
    data?: HTTPClientRequestPayload,
    headers?: HTTPClientRequestHeaders,
    responseType?: AxiosRequestConfig['responseType'],
    config?: AxiosRequestConfig,
    noAuthHeader = false
  ): Promise<unknown> {
    const token = sessionStorage.getItem('pfz-cggenai-token-id');
    const authHeader =
      !noAuthHeader && token ? { Authorization: `Bearer ${token}` } : {};

    const commonOptions = {
      ...config,
      headers: {
        ...headers,
        ...authHeader,
      },
    };

    if (responseType) {
      (commonOptions as AxiosRequestConfig).responseType = responseType;
    }

    try {
      let response: AxiosResponse<T>;

      if (method === 'get') {
        response = await this.apiInstance.get(url, {
          ...commonOptions,
          params: data,
        });
      } else if (method === 'delete') {
        response = await this.apiInstance.delete(url, {
          ...commonOptions,
          params: data,
        });
      } else if (method === 'patch') {
        response = await this.apiInstance.patch(url, data, commonOptions);
      } else if (method === 'put') {
        response = await this.apiInstance.put(url, data, commonOptions);
      } else {
        // Assuming the method is 'post'
        response = await this.apiInstance.post(url, data, commonOptions);
      }

      return this.createSuccessResponse(response);
    } catch (error) {
      return HttpClient.createErrorResponse(error as AxiosError);
    }
  }

  public get<T>(
    url: string,
    params?: HTTPClientRequestPayload,
    headers?: HTTPClientRequestHeaders,
    responseType?: AxiosRequestConfig['responseType']
  ): Promise<unknown> {
    return this.request<T>('get', url, params, headers, responseType);
  }

  public delete<T>(
    url: string,
    params?: HTTPClientRequestPayload,
    headers?: HTTPClientRequestHeaders
  ): Promise<unknown> {
    return this.request<T>('delete', url, params, headers);
  }

  public post<T>(
    url: string,
    data?: HTTPClientRequestPayload,
    headers?: HTTPClientRequestHeaders
  ): Promise<unknown> {
    return this.request<T>('post', url, data, headers);
  }

  public put<T>(
    url: string,
    data?: HTTPClientRequestPayload,
    headers?: HTTPClientRequestHeaders,
    config?: AxiosRequestConfig,
    noAuthHeader?: boolean
  ): Promise<unknown> {
    return this.request<T>(
      'put',
      url,
      data,
      headers,
      undefined,
      config,
      noAuthHeader
    );
  }

  public patch<T>(
    url: string,
    data?: HTTPClientRequestPayload,
    headers?: HTTPClientRequestHeaders
  ): Promise<unknown> {
    return this.request<T>('patch', url, data, headers);
  }

  static createErrorResponse(error: AxiosError) {
    return {
      success: false,
      status: get(error, 'response.status', null),
      statusText: get(error, 'response.statusText', ''),
      message: get(error, 'response.data.errors.message', error.message),
      errorDetails: get(error, 'response.data.errors.details', ''),
    };
  }

  private createSuccessResponse(res: AxiosResponse) {
    let data;

    if (get(res, 'config.responseType') === 'arraybuffer') {
      data = get(res, 'data', null);
    } else {
      data = get(res, this.dataExtractor, null);
    }

    return {
      success: true,
      status: get(res, 'status', null),
      statusText: get(res, 'statusText', ''),
      message: get(res, 'message', ''),
      headers: get(res, 'headers', ''),
      data,
    };
  }
}

// Exports
export const httpClient = new HttpClient({ baseURL: getAPIGatewayEndpoint() });
export type HTTPClientRequestPayload = AxiosRequestConfig['data'];
export type HTTPClientRequestHeaders = AxiosRequestConfig['headers'];
export type HTTPClientResponse = AxiosResponse;
