import { ANALYTICS } from '../../constants/common';
import { trackCustomLinkUsage, trackFormInput } from './analyticEvents';

/**
 * @method trackReferencesPanelOpen
 * @description this method tracks the page level translation
 * @param {string} formName (form name) [name of the form]
 * @param {string} selectedBrand (selected Brand) [selected Brand value]
 * @param {string} selectedContentType (selected contenttype) [selected contenttype value]
 * @param {string} targetAudience (selected target audience) [name of the selected target audience in the prompt form]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 * @param {object} [additionalData] (optional) [additional key-value pairs to track]
 */
export const trackReferencesPanelOpen = (
  formName: string,
  selectedBrand: string,
  selectedContentType: string,
  targetAudience: string,
  userEncodedNtid: string,
  additionalData?: Record<string, string>
) => {
  trackFormInput(formName, ANALYTICS.STEP, 'brand', `${selectedBrand}`, '1');
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'channel',
    `${selectedContentType}`,
    '2'
  );
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'targetAudience',
    `${targetAudience}`,
    '3'
  );

  if (userEncodedNtid !== '') {
    trackFormInput(
      formName,
      ANALYTICS.NUMBER_ONE,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '4'
    );
  }

  // Track additional data if provided
  if (additionalData) {
    Object.entries(additionalData).forEach(([key, value], index) => {
      trackFormInput(
        formName,
        ANALYTICS.STEP,
        key,
        value,
        (index + 5).toString() // Start index after 4
      );
    });
  }
};

/**
 * @method trackReferenceLinkClick
 * @description This method tracks the  claims and refernces link click
 */
export const trackReferenceLinkClick = (referencesLinkName: string) => {
  trackCustomLinkUsage(referencesLinkName, 'external');
};

/**
 * @method trackClaimsLibraryLaunch
 * @description this method tracks the page level translation
 * @param {string} formName (form name) [name of the form]
 * @param {string} selectedBrand (selected Brand) [selected Brand value]
 * @param {string} selectedContentType (selected contenttype) [selected contenttype value]
 * @param {string} targetAudience (selected target audience) [name of the selected target audience in the prompt form]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 * @param {object} [additionalData] (optional) [additional key-value pairs to track]
 */
export const trackClaimsLibraryLaunch = (
  formName: string,
  selectedBrand: string,
  selectedContentType: string,
  targetAudience: string,
  userEncodedNtid: string,
  additionalData?: Record<string, string>
) => {
  trackFormInput(formName, ANALYTICS.STEP, 'brand', `${selectedBrand}`, '1');
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'channel',
    `${selectedContentType}`,
    '2'
  );
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'targetAudience',
    `${targetAudience}`,
    '3'
  );

  if (userEncodedNtid !== '') {
    trackFormInput(
      formName,
      ANALYTICS.STEP,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '4'
    );
  }

  // Track additional data if provided
  if (additionalData) {
    Object.entries(additionalData).forEach(([key, value], index) => {
      trackFormInput(
        formName,
        ANALYTICS.STEP,
        key,
        value,
        (index + 5).toString() // Start index after 5
      );
    });
  }
};
