import store from '../../index';
import {
  RefineOptions,
  refineOptionsStateSegment,
} from '../../stateSegments/refineOptionsStateSegment';

export const updateAdvancedFilters = (advancedFilters: RefineOptions) => {
  store.set(refineOptionsStateSegment, (prev) => {
    return { ...prev, ...advancedFilters };
  });
};
