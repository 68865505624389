/**
 * @description This file has methods to trigger analytic events for copy feature
 */

import { ANALYTICS } from '../../constants/common';
import { trackFormInput } from './analyticEvents';

/**
 * @method trackCopyContent
 * @description this method track type of content-copy with selected brand and channel
 * @param {string} formName (form name) [name of the form]
 * @param {string} brand (selected brand name) [name of the brand selected in the prompt form]
 * @param {string} channel (selected channel name) [name of the channel selected in the prompt form]
 * @param {string} targetAudience (selected target audience) [name of the selected target audience in the prompt form]
 * @param {string} userEncodedNtid (user encoded ntid ) [user encoded ntid]
 * @param {object} [additionalData] (optional) [additional key-value pairs to track]
 */
export const trackCopyContent = (
  formName: string,
  brand: string,
  channel: string,
  targetAudience: string,
  userEncodedNtid: string,
  additionalData?: Record<string, string>
) => {
  trackFormInput(formName, ANALYTICS.STEP, 'brand', `${brand}`, '1');
  trackFormInput(formName, ANALYTICS.STEP, 'channel', `${channel}`, '2');
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'targetAudience',
    `${targetAudience}`,
    '3'
  );

  if (userEncodedNtid !== '') {
    trackFormInput(
      formName,
      ANALYTICS.STEP,
      ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME,
      userEncodedNtid,
      '4'
    );
  }

  // Track additional data if provided
  if (additionalData) {
    Object.entries(additionalData).forEach(([key, value], index) => {
      trackFormInput(
        formName,
        ANALYTICS.STEP,
        key,
        value,
        (index + 5).toString() // Start index after 4
      );
    });
  }
};
