import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

export type SupportingInformation = {
  isiContent: {
    id: string;
    text: string;
  };
  piContent: Array<{
    id: string;
    label: string;
    link: string;
  }>;
  error: boolean;
  loading: boolean;
};

export const supportingInformationStateSegment =
  stateSegmentWithReset<SupportingInformation>({
    isiContent: { id: '', text: '' },
    piContent: [],
    error: false,
    loading: false,
  });
supportingInformationStateSegment.debugLabel =
  'supportingInformationStateSegment';
