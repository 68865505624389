import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import { ParamsUsed as ParamsType } from '../../../types/store/markettingContentGeneration/majorProcessActions';

export const contentParamsSegment = stateSegmentWithReset<ParamsType>({
  // options
  currentAvailableActionOptions: [],
  currentAvailableBrandOptions: [],
  currentAvailableCountryOptions: [],
  currentAvailableIndicationOptions: [],
  currentAvailableAudienceOptions: [],
  currentAvailableContentTypeOptions: [],
  currentAvailableSegmentOptions: [],
  currentAvailableSectionOptions: [],
  currentAvailableToneOptions: [],
  currentAvailablePlatformOptions: [],
  currentAvailableTopicOptions: [],
  currentAvailableToneStyleOptions: [],
  currentAvailableDestinationFormatOptions: [],

  // selections
  currentSelectedBrand: '',
  currentSelectedCountry: '',
  currentSelectedIndication: '',
  currentSelectedAudience: '',
  currentSelectedContentType: '',
  currentSelectedSegment: '',
  currentSelectedPlatform: '',
  currentSelectedSections: [],
  currentSelectedTone: '',
  currentSelectedTopic: [],
  currentSelectedToneStyle: '',
  currentSelectedExclusion: [],
  currentSelectedModules: '',
  currentSelectedInclusion: [],
  currentSelectedAction: '',
  currentSelectedDestinationFormat: '',
  currentSelectedFile: '',
});
contentParamsSegment.debugLabel = 'contentParamsSegment';
