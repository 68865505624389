import store from '../..';
import { imageCaptionStateSegment } from '../../stateSegments/imageCaptionStateSegment';

export const updateImageCaptionLoaders = ({
  isLoadingImageCaption,
  errorLoadingImageCaption,
}: {
  isLoadingImageCaption: boolean;
  errorLoadingImageCaption: boolean;
}) => {
  store.set(imageCaptionStateSegment, () => {
    return {
      errorLoadingImageCaption,
      isLoadingImageCaption,
    };
  });
};
