import store from '../../index';
import { fetchPreGeneratedContent } from '../../../../services/Content';
import { Claim } from '../../../../types/Claims';
import { ParamsUsed } from '../../../../types/store/markettingContentGeneration/majorProcessActions';
import { getGenerateContentPayload } from '../../helpers/common';
import { selectedChannel } from '../../stateSegments/contentListSegment';
import { preGenerateCliamsStateSegment } from '../../stateSegments/preGenerateClaimsStateSegment';

export const fetchPregenratedClaims = async (paramsUsed: ParamsUsed) => {
  const { currentSelectedContentType } = paramsUsed;
  store.set(preGenerateCliamsStateSegment, (prev) => {
    return {
      ...prev,
      isPrefetchedClaimsLoading: true,
      isPrefetchedClaimsError: false,
      isPrefetchedClaimsSuccess: false,
      preFetchedclaimsList: [] as Claim[],
    };
  });

  store.set(selectedChannel, currentSelectedContentType);

  const payload = getGenerateContentPayload(paramsUsed);
  const { success, data } = await fetchPreGeneratedContent(payload);

  if (success) {
    sessionStorage.setItem('pfz-cggenai-session-id', data?.sessionId || '');

    sessionStorage.setItem(
      'pfz-cggenai-content-group-id',
      data?.contentGroupId || ''
    );
    store.set(preGenerateCliamsStateSegment, (prev) => {
      return {
        ...prev,
        isPrefetchedClaimsLoading: false,
        isPrefetchedClaimsError: false,
        isPrefetchedClaimsSuccess: true,
        preFetchedclaimsList: (data?.claims || []) as Claim[],
      };
    });
  } else {
    store.set(preGenerateCliamsStateSegment, (prev) => {
      return {
        ...prev,
        isPrefetchedClaimsLoading: false,
        isPrefetchedClaimsError: true,
        isPrefetchedClaimsSuccess: false,
        preFetchedclaimsList: [] as Claim[],
      };
    });
  }
};
