import { atom as stateSegment } from 'jotai';
import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

import {
  EmailScreenName,
  ModuleContent,
  SelectedModule,
} from '../../../types/store/markettingContentGeneration/emailModules';

export const selectedModulesStateSegment = stateSegmentWithReset<
  Map<string, SelectedModule>
>(new Map());

selectedModulesStateSegment.debugLabel = 'selectedModulesStateSegment';

export const moduleContentsStateSegment = stateSegmentWithReset<
  Map<string, ModuleContent>
>(new Map());

moduleContentsStateSegment.debugLabel = 'moduleContentsStateSegment';

export const activeEmailScreenStateSegment =
  stateSegmentWithReset<EmailScreenName>(null);

activeEmailScreenStateSegment.debugLabel = 'activeEmailScreenStateSegment';

export const emailContentParentId = stateSegmentWithReset('');

emailContentParentId.debugLabel = 'emailContentParentId';

export const hasStartedGeneratingContent = stateSegment((get) => {
  const moduleContents = get(moduleContentsStateSegment);
  return Array.from(moduleContents.values()).some(
    (value) => value.status !== 'Not Started'
  );
});

hasStartedGeneratingContent.debugLabel = 'hasStartedGeneratingContent';

export const previouslySelectedModules = stateSegmentWithReset<
  Map<string, SelectedModule>
>(new Map());

previouslySelectedModules.debugLabel = 'previouslySelectedModules';
