import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import { Claim } from '../../../types/Claims';

type PrefetchedClaims = {
  isPrefetchedClaimsLoading: boolean;
  isPrefetchedClaimsError: boolean;
  isPrefetchedClaimsSuccess: boolean; // indicates the success of prefetch api
  preFetchedclaimsList: Claim[];
};

export const preGenerateCliamsStateSegment =
  stateSegmentWithReset<PrefetchedClaims>({
    isPrefetchedClaimsLoading: false,
    isPrefetchedClaimsError: false,
    isPrefetchedClaimsSuccess: false,
    preFetchedclaimsList: [],
  });

preGenerateCliamsStateSegment.debugLabel = 'preGenerateCliamsStateSegment';
