import { ImageInfoConfig } from '../constants/common';

export type BannerFrameType = {
  body: string;
};

export type ContentOptions = {
  frames: BannerFrameType[];
  optionId: string;
  call_to_action: string;
};

export type BannerFramesListType = {
  id: string;
  parentId: string;
  versionSequence: number;
  contentOptions: ContentOptions[];
  imagesRecommend?: ImageRecommendation[];
  score: {
    value: number;
  };
};

// export interface ContentResult {
//   message: string;
//   status: 200;
//   statusText: '';
//   success: boolean;
//   data: {
//     sessionId: string;
//     action: string;
//     channel: string;
//     contentType: ContentType;
//     status: string;
//     generatedContent: GeneratedContentData[];
//   };
// }

// Define the common parts of the response
export interface CommonContentResult {
  message: string;
  status: 200;
  statusText: string;
  success: boolean;
  data: {
    sessionId: string;
    action: string;
    channel: string;
    contentType: ContentType;
    status: string;
  };
}

// Define the response structure with generated content
export interface ContentGeneratedResult extends CommonContentResult {
  data: {
    generatedContent: GeneratedContentData[];
  } & Omit<CommonContentResult['data'], 'generatedContent'>;
}

// Define the response structure with translated content
export interface ContentTranslatedResult extends CommonContentResult {
  data: {
    translatedContent: GeneratedContentData[];
  } & Omit<CommonContentResult['data'], 'translatedContent'>;
}

export interface ImagesResult {
  message: '';
  status: 200;
  statusText: '';
  success: true;
  data: {
    id: string;
    channel: string;
    content: [
      {
        optionId: string;
        // for social media only
        recommendedImages?: string[];
        imagesReferenceFrame: string;
        frames: [
          {
            id: string;
            recommendedImages: [
              {
                imageName: string;
              },
            ];
          },
        ];
      },
    ];
  };
}

// Interface for recommended images
export interface RecommendedImage {
  imageId: string;
  imageName: string;
  caption: string;
}

// Interface for frames containing recommended images
export interface Frame {
  recommendedImages: RecommendedImage[];
}

// Interface for image recommendations
export interface ImageRecommendation {
  optionId: string;
  imagesReferenceFrame?: string; // Optional, as not all objects have this
  frames?: Frame[]; // Optional, as not all objects have this
  recommendedImages?: RecommendedImage[]; // Optional, as not all objects have this
}

// Interface for the main data structure
export interface ImageData {
  id: string;
  sessionId: string;
  action: string;
  channel: string;
  imagesRecommend: ImageRecommendation[];
}

export interface ImageApiResponse {
  success: boolean;
  message: string;
  errors: string[];
  data: ImageData;
}

export interface CannedParametersResult {
  message?: '';
  status?: 200;
  statusText?: '';
  success?: true;
  data: CannedPramsResponseType;
}

export type CannedParams = {
  id: string;
  label: string;
  name?: string;
  recommend?: boolean;
};

export type CannedPramsResponseType = {
  items: CannedParams[];
};

export interface ApiError {
  success: boolean;
  message: string;
  errors?: string[];
  data: unknown;
  status: number;
}

export interface FeedbackResult {
  success: boolean;
  message: string;
  errors?: string[];
  data: unknown;
  status: number;
}

export interface TrackContentCopyResult {
  success: true;
  message: string;
  errors: string[];
  data: unknown;
}

export enum ContentType {
  BANNER = 'Banner',
  DSP = 'DSP',
  EMAIL = 'Email',
  SOCIAL = 'Social Media',
}

export type SegmentType = {
  subHeader: string;
  texts: string[];
};

export type SectionsType = {
  name: string;
  body: string | SegmentType[];
};

export enum ActionNames {
  COPY = 'COPY',
  COPY_TEXT = 'COPY_TEXT',
  REGENERATE = 'REGENERATE',
}

export enum ActionLevel {
  SEGMENT = 'segment',
  SECTION = 'section',
}

export interface Action {
  name: string;
  label: string;
  enabled: boolean;
}

export interface ContentsDataType {
  title: string;
  actionLevel?: ActionLevel;
  sections: SectionsType[];
}

export interface ContentTypeImage {
  name: string;
  altText: string;
  frameId: string;
  id: string;
  caption?: string;
  translatedCaption?: string;
}

export interface BannerContentType {
  title: string;
  frames: string[];
  optionId: string;
  isLoading: boolean;
}

export interface EmailContentSection {
  path: string;
  name: string;
  title: string;
  markdownText: string;
  isLoading?: boolean;
}
export interface EmailContent {
  optionId: string;
  topSection: {
    title: string;
    preHeader: EmailContentSection;
    subjectLineOptions: EmailContentSection;
    previewTextOptions: EmailContentSection;
    isLoading?: boolean;
  };
  emailBody: {
    title: string;
    introCopy: EmailContentSection;
    callToActionsOption: EmailContentSection;
  };
  isLoading?: boolean;
}

export type SocialContentForm = {
  name: string;
  title: string;
  isLoading: boolean;
  headline: {
    name: string;
    title: string;
    text: string;
  };
  body: {
    name: string;
    title: string;
    text: string;
  };
  hashtags: {
    name: string;
    title: string;
    text: string;
  };
};

export type SocialContent = {
  title: string;
  optionId: string;
  isLoading: boolean;
  shortForm: SocialContentForm;
  longForm: SocialContentForm;
};

export interface ClaimReferences {
  id: string;
  code: string;
  description: string;
  documentNames: string[];
  referenceLink: string;
  status: string;
  isSubstitute: boolean;
}

export interface ReferencesResponseData {
  id: string;
  channel: string;
  contentReferences: ClaimReferences[]; // updated key from claimReferences as per swagger
}

export interface ReferencesResult {
  success: boolean;
  message: string;
  errors?: string[];
  data: ReferencesResponseData | null;
  status: number;
}

export interface ReferencesResponse {
  success: boolean;
  data?: ReferencesResponseData | [];
}

export interface AnalyticsData {
  brand: string;
  contentType: string;
}

export type ImageRecomendationInfoType = {
  type: ImageInfoConfig;
  labelText: string;
  ctaText: string;
};

export type GetGeneratedContentParams = {
  sessionId: string;
  contentGroupId: string;
  contentId: string;
  action: string;
};

type Score = {
  value: number;
};

type BannerImageNames = {
  imageName: string;
};

type BannerRecommendedImagesByFrames = {
  recommendedImages: BannerImageNames[];
};

export type BannerRecommendedImages = {
  optionId: string;
  imagesReferenceFrame: string;
  frames: BannerRecommendedImagesByFrames[];
};

export type RecommendedImages = {
  optionId: string;
  recommendedImages: string[];
};

export type ImagesRecommended = {
  id: string;
  parentId: string;
  versionSequence: number;
  contentImages: RecommendedImages[];
};

export type GeneratedSocialContent = {
  optionId: string;
  shortForm: {
    headline: string;
    body: string;
    hashtags: string[];
  };
  longForm: {
    headline: string;
    body: string;
    hashtags: string[];
  };
};

export type ContentImages = {
  imageBlob: Blob;
  imageName: string;
  imageType: string;
};

export type EmailModuleOptionsContent = {
  optionId: string;
  header?: string;
  preHeader?: string;
  body?: string;
  closingBody?: string;
  callToAction?: string;
  subheader?: string;
  bulletedCopy: string[];
  subjectLine?: string;
  previewText?: string;
  images?: ContentImages[];
};

export type EmailModuleAttributes = {
  id: string;
  name: string;
  code: string;
  sequence: number;
};

export type EmailModuleContentResult = {
  id: string;
  parentId: string;
  versionSequence: number;
  name: string;
  attributes: EmailModuleAttributes[];
  contentOptions: EmailModuleOptionsContent[];
  score: {
    value: number;
  };
};

export type EmailContentResult = {
  message: string;
  status: 200;
  statusText: '';
  success: boolean;
  data: {
    sessionId: string;
    action: string;
    channel: string;
    generatedContent: EmailModuleContentResult[];
  };
};

export type GeneratedModuleContent = {
  id: string;
  parentId: string;
  name: string;
  versionSequence: number;
  score: Score;
  attributes: EmailModuleAttributes[];
  contentOptions: EmailModuleOptionsContent[];
  imagesRecommend?: ImageRecommendation[];
};

export interface GeneratedBannerContentData extends BannerFramesListType {}

export interface GeneratedSocialContentData {
  id: string;
  parentId: string;
  versionSequence: number;
  contentOptions: GeneratedSocialContent[];
  imagesRecommend?: ImageRecommendation[];
  score: Score;
}

export type GeneratedContentData =
  | GeneratedModuleContent
  | GeneratedBannerContentData
  | GeneratedSocialContentData;

/** Generated-Content API response structure */
export interface GeneratedContentResultData {
  sessionId: string;
  action: string;
  channel: string;
  generatedContent: GeneratedContentData[];
  translatedContent: GeneratedContentData[];
}

export interface GeneratedContentResult {
  success: boolean;
  message?: string;
  errors?: string[];
  data?: GeneratedContentResultData;
}

/** Generate API response structure */
export interface GenerateResultData {
  sessionId: string;
  action: string;
  channel: string;
  generatedContent: GeneratedContentData[];
}

export interface GenerateResult {
  success: boolean;
  message?: string;
  errors?: string[];
  data?: GenerateResultData;
}

export interface TranslatedContent extends GeneratedModuleContent {
  langCode?: string;
  langLabel?: string;
}
