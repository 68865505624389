import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

import { GeneratedContent } from '../../../types/transformedData/MarkettingContentGeneration';

export type TranslatedData = {
  langCode: string;
  isFetched: boolean;
  data: GeneratedContent['data'];
  retryParams?: {
    currentViewVersion: number;
    langCode: string;
    optionId?: string;
    sections?: string[];
    loaderPath?: string;
  };
};

export type TranslationHashMap = {
  isTranslatingContent: boolean;
  isPartialTranslating: boolean;
  errorTranslatingContent: boolean;
  errorPartialTranslating: boolean;
  errorMessage: string;
  map: Record<number, TranslatedData>;
};

export const contentTranslationStateSegment =
  stateSegmentWithReset<TranslationHashMap>({
    isTranslatingContent: false,
    errorTranslatingContent: false,
    errorPartialTranslating: false,
    isPartialTranslating: false,
    errorMessage: '',
    map: {},
  });
contentTranslationStateSegment.debugLabel = 'contentTranslationStateSegment';
